import React, { useEffect, useMemo, useState, ReactElement, memo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { clamp, toInteger, toNumber } from 'lodash';
import { formatNumber } from 'utils';
import { Button, Icon, Input, NativeSelect } from 'components/semantic';
import { useIsTablet } from 'utils/media';
import useURLSearchParam, { useApplyChanges } from 'utils/use-url-search-param';
import styles from './index.module.scss';

const PAGE_SIZES = [10, 20, 40, 60, 100] as const;
export type PageSize = typeof PAGE_SIZES[number];
const PAGE_SIZE_OPTIONS = PAGE_SIZES.map((item) => item.toString());
export interface PaginationProps {
  count: number;
  defaultPageSize?: PageSize;
  meta?: ReactElement;
  className?: string;
}
const DEFAULT_DEFAULT_PAGE_SIZE = 20;
const PAGE_SIZE_KEY = 'pageSize';
const PAGE_KEY = 'page';

export const usePagination = (defaultPageSize: PageSize = DEFAULT_DEFAULT_PAGE_SIZE) => {
  const [urlPage, , changeUrlPage] = useURLSearchParam(PAGE_KEY);
  const [urlPageSize, , changeUrlPageSize] = useURLSearchParam(PAGE_SIZE_KEY);
  const applyChanges = useApplyChanges();
  const { page, pageSize, limit, skip } = useMemo(() => {
    const pageTmp = urlPage ? Math.max(toInteger(urlPage), 1) : 1;
    const pageSizeTmp = urlPageSize
      ? Math.max(toInteger(urlPageSize), PAGE_SIZES[0])
      : defaultPageSize;
    return {
      page: pageTmp,
      pageSize: pageSizeTmp,
      limit: pageSizeTmp,
      skip: (pageTmp - 1) * pageSizeTmp,
    };
  }, [urlPage, urlPageSize, defaultPageSize]);
  const changePage = useCallback(
    (pageValue?: number) =>
      pageValue === undefined ? changeUrlPage() : changeUrlPage(`${pageValue}`),
    [changeUrlPage]
  );
  const changePageSize = useCallback(
    (pageSizeValue?: number) =>
      pageSizeValue === undefined ? changeUrlPageSize() : changeUrlPageSize(`${pageSizeValue}`),
    [changeUrlPageSize]
  );
  return {
    page,
    pageSize,
    limit,
    skip,
    changePage,
    changePageSize,
    applyChanges,
  };
};

const Pagination = ({
  count,
  defaultPageSize = DEFAULT_DEFAULT_PAGE_SIZE,
  meta,
  className,
}: PaginationProps) => {
  const { t } = useTranslation();
  const { pageSize, page, changePage, changePageSize, applyChanges } = usePagination(
    defaultPageSize
  );
  const total = useMemo(() => Math.ceil(count / pageSize), [count, pageSize]);
  const [inputPage, setInputPage] = useState<number | string>(page);
  useEffect(() => setInputPage(page), [page]);
  const onPageSizeChange = (size: number) => {
    if (size === pageSize) {
      return;
    }
    applyChanges([changePageSize(size), changePage()]);
  };
  const onPageChange = (value: number) => {
    value = clamp(value, 1, total);
    if (value !== page) {
      applyChanges([changePage(value)]);
    }
  };
  const inputPageJump = () => {
    // 为 '' 时 回滚值
    if (inputPage === '') {
      setInputPage(page);
      return;
    }
    let numValue = toNumber(inputPage);
    if (isNaN(numValue)) {
      setInputPage(page);
      return;
    }
    numValue = clamp(numValue, 1, total);
    setInputPage(numValue);
    onPageChange(numValue);
  };
  const isTablet = useIsTablet();

  return (
    <div className={classNames(styles.pagination, 'fill-space', className)}>
      <span className={styles.info}>
        {meta && <span className={styles.meta}>{meta} · </span>}
        <Trans i18nKey="pagination.display" values={{ total: formatNumber(count) }}>
          Total <b /> items ·
          <NativeSelect
            options={PAGE_SIZE_OPTIONS}
            value={pageSize.toString()}
            onChange={(e, { value }) => onPageSizeChange(toNumber(value))}
            size="small"
          />
          items per page
        </Trans>
      </span>
      <span className="space" />
      <span className={styles.pager}>
        <span className={styles.page}>
          <Input
            type="number"
            className={styles.pageInput}
            size="small"
            value={inputPage}
            onChange={(e, { value }) => setInputPage(value)}
            onBlur={inputPageJump}
            onKeyDown={(e: { keyCode: number }) => {
              // enter
              if (e.keyCode === 13) {
                inputPageJump();
              }
            }}
          />
          <span>{t('pagination.totalPage', { total: formatNumber(total) })}</span>
        </span>
        <span>
          <Button
            className={styles.pageButton}
            disabled={page <= 1}
            size="tiny"
            icon={isTablet ? <Icon name="arrow left" /> : undefined}
            labelPosition={isTablet ? 'left' : undefined}
            onClick={(e) => onPageChange(page - 1)}
            content={t('pagination.prePage')}
          />
          <Button
            className={styles.pageButton}
            disabled={page >= total}
            size="tiny"
            icon={isTablet ? <Icon name="arrow right" /> : undefined}
            labelPosition={isTablet ? 'right' : undefined}
            onClick={(e) => onPageChange(page + 1)}
            content={t('pagination.nextPage')}
          />
        </span>
      </span>
    </div>
  );
};

export default Pagination;

export const LoadMorePagination = memo<{
  allLoaded: boolean;
  loading?: boolean;
  loadMore: () => void;
}>(({ loading, loadMore, allLoaded }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.loadMore}>
      {allLoaded ? (
        <span className={styles.allLoaded}>{t('pagination.allLoaded')}</span>
      ) : (
        <Button disabled={loading} onClick={(e) => loadMore()}>
          {t('pagination.loadMore')}
        </Button>
      )}
    </div>
  );
});
