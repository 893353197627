import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { currency } from 'config';
import _ from 'lodash';
import moment from 'moment';
import { TimeFormat } from 'utils';
import Panel from 'components/Panel';
import Placeholder from 'components/Placeholder';
import { Item } from 'components/semantic';
import { TimeSerialData, TimeSerialTinyBar } from 'components/StatisticView';
import { useAccountMoney } from 'App/AccountMoney';
import styles from './index.module.scss';
import { useServiceBill } from './utils';

const START_DATE = moment().subtract(14, 'day').toDate();

const AccountBalance = memo(() => {
  const { t } = useTranslation();
  const [accountMoney] = useAccountMoney();
  return (
    <Item
      meta={t('account.balance')}
      description={
        <Item.Description>
          {accountMoney ? (
            <>
              <span
                className={classnames('emphasis-figure', styles.balance, {
                  'text-danger': accountMoney && accountMoney.amount < 0,
                })}
              >
                {accountMoney.amount}
                {/* 返回有货币单位 */}
                <span className={styles.currency}>{accountMoney.currency}</span>
              </span>
              <Link to="account/finance/recharge" className={styles.action}>
                {t('action.recharge')}
              </Link>
              <Link to="account/alert" className={styles.action}>
                {t('account.settings.alert')}
              </Link>
            </>
          ) : (
            <Placeholder />
          )}
        </Item.Description>
      }
    />
  );
});

const AccountSpendingHistory = memo(() => {
  const { t } = useTranslation();
  const [bill, { loading }] = useServiceBill(START_DATE);
  const billChartData = useMemo(() => {
    const billData = _(bill).groupBy('bill_date').value();
    return new Array(14).fill(0).map((v, index) => {
      const date = moment(START_DATE).add(index, 'day');
      const dateStr = date.format(TimeFormat.QUERY);
      let amount = 0;
      if (billData[dateStr]) {
        amount = _.sumBy(billData[dateStr], (item) => item.amount);
      }
      return [
        moment(START_DATE).add(index, 'day'),
        {
          amount,
        },
      ] as TimeSerialData;
    });
  }, [bill]);
  return (
    <Item
      meta={t('billing.history.recent')}
      description={
        <Item.Description>
          <span className={styles.trendChart}>
            <TimeSerialTinyBar data={billChartData} loading={loading} unit={currency} height={33} />
          </span>
          <Link to="account/billing/details" className={styles.action}>
            {t('action.viewDetail')}
          </Link>
        </Item.Description>
      }
    />
  );
});

export default memo(() => {
  const { t } = useTranslation();
  return (
    <Panel title={t('finance.overview')}>
      <Item.Group className={styles.financeContainer}>
        <AccountBalance />
        <AccountSpendingHistory />
      </Item.Group>
    </Panel>
  );
});
