/* eslint-disable i18n/no-chinese-character */
import React, { useState } from 'react';
import { Noop } from 'utils';
import ExternalLink from 'components/ExternalLink';
import {
  Button,
  FileInput,
  Form,
  Input,
  LinkButton,
  Modal,
  Popup,
  withModal,
} from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import License from '../images/business-license.jpg';
import IDCardBack from '../images/id-card-back.jpg';
import IDCardFront from '../images/id-card-front.jpg';

const MAX_FILE_SIZE = 512 * 1000;
const SIZE_MESSAGE = '图片大小上限为 512 KB（512 x 1000 B）';

const fileSizeLimit = (setState: React.Dispatch<React.SetStateAction<File | undefined>>) => {
  return (file?: File) => {
    if (file && file.size > MAX_FILE_SIZE) {
      setState(undefined);
      toast.error(SIZE_MESSAGE);
    } else {
      setState(file);
    }
  };
};

export default withModal<{ onSucceeded: Noop }>()(({ close, onSucceeded }) => {
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [license, setLicense] = useState<File>();
  const [legalPersonName, setLegalPersonName] = useState('');
  const [legalPersonId, setLegalPersonId] = useState('');
  const [IDFront, setIDFront] = useState<File>();
  const [IDBack, setIDBack] = useState<File>();
  const [transferReceipt, setTransferReceipt] = useState<File>();

  const verify = () => {
    const form = new FormData();
    form.append('clientType', '1');
    form.append('company', name);
    form.append('companyLicenseId', id);
    if (license) {
      form.append('companyLicenseFile', license);
    }
    form.append('companyOwnerName', legalPersonName);
    form.append('companyOwnerId', legalPersonId);
    if (IDFront) {
      form.append('companyOwnerIdFileFront', IDFront);
    }
    if (IDBack) {
      form.append('companyOwnerIdFileBack', IDBack);
    }
    if (transferReceipt) {
      form.append('companyTransferReceiptFile', transferReceipt);
    }
    return request(
      `/client-center/${CLIENT_CENTER_VERSION}/clients/self/updateClientVerification`,
      {
        method: 'POST',
        body: form,
      }
    )
      .then(() => {
        toast.success('企业认证申请提交成功，我们将在 2 个工作日内审核。');
        onSucceeded();
      })
      .catch((error) => toast.error(error.message));
  };

  return (
    <>
      <Modal.Header>企业实名认证</Modal.Header>
      <Modal.Content>
        <Form id="verify" onSubmit={verify}>
          <Form.Field required>
            <label htmlFor="name">公司名称</label>
            <Input
              id="name"
              value={name}
              required
              autoFocus
              onChange={(e, data) => {
                setName(data.value);
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="id">营业执照注册号</label>
            <Input
              id="id"
              value={id}
              required
              onChange={(e, data) => {
                setId(data.value);
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="license">
              营业执照扫描件
              <Popup
                hoverable
                trigger={<LinkButton content="示例" inline type="button" />}
                content={<img src={License} width="200" alt="营业执照扫描件示例" />}
                inverted={false}
                position="right center"
              />
            </label>
            <FileInput
              id="license"
              file={license}
              onChange={fileSizeLimit(setLicense)}
              required
              accept="image/jpg,image/jpeg,image/png"
            />
            <ul className="help-block">
              <li>证件信息需清晰可见</li>
              <li>图片支持 jpg、jpeg、png 格式</li>
            </ul>
          </Form.Field>
          <Form.Field required>
            <label htmlFor="legalPersonName">法人姓名</label>
            <Input
              id="legalPersonName"
              value={legalPersonName}
              required
              onChange={(e, data) => {
                setLegalPersonName(data.value);
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="legalPersonId">法人身份证号</label>
            <Input
              id="legalPersonId"
              value={legalPersonId}
              required
              onChange={(e, data) => {
                setLegalPersonId(data.value);
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="IDFront">
              法人身份证人像面扫描件
              <Popup
                hoverable
                trigger={<LinkButton content="示例" inline type="button" />}
                content={<img src={IDCardFront} width="200" alt="身份证人像面扫描件示例" />}
                inverted={false}
                position="right center"
              />
            </label>
            <FileInput
              id="IDFront"
              file={IDFront}
              onChange={fileSizeLimit(setIDFront)}
              required
              accept="image/jpg,image/jpeg,image/png"
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="IDBack">
              法人身份证国徽面扫描件
              <Popup
                hoverable
                trigger={<LinkButton content="示例" inline type="button" />}
                content={<img src={IDCardBack} width="200" alt="身份证国徽面扫描件示例" />}
                inverted={false}
                position="right center"
              />
            </label>
            <FileInput
              id="IDBack"
              file={IDBack}
              onChange={fileSizeLimit(setIDBack)}
              required
              accept="image/jpg,image/jpeg,image/png"
            />
            <ul className="help-block">
              <li>证件信息需清晰可见</li>
              <li>图片支持 jpg、jpeg、png 格式</li>
              <li>{SIZE_MESSAGE}</li>
            </ul>
          </Form.Field>

          <Form.Field required>
            <label htmlFor="transferReceipt">对公转账截图</label>
            <FileInput
              id="transferReceipt"
              file={transferReceipt}
              onChange={fileSizeLimit(setTransferReceipt)}
              required
              accept="image/jpg,image/jpeg,image/png"
            />
            <ul className="help-block">
              <li>
                前往
                <ExternalLink href="/account/finance/recharge">「财务概览 &gt; 充值」</ExternalLink>
                ，使用公司账户进行任意金额的充值并提供付款凭证截图。
              </li>
              <ul>
                <li>你可以通过银行对公付款方式向 LeanCloud 公司账户转账后提供银行付款凭证截图。</li>
                <li>
                  你也可以使用 <strong>企业支付宝账户</strong> 充值后提供支付宝充值截图。
                </li>
              </ul>
              <li>图片支持 jpg、jpeg、png 格式</li>
              <li>{SIZE_MESSAGE}</li>
            </ul>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton content="关闭" onClick={close} />
        <Button type="submit" form="verify" content="提交" primary />
      </Modal.Actions>
    </>
  );
});
