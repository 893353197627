import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { currency } from 'config';
import _ from 'lodash';
import { formatMoney } from 'utils';
import { Card, Placeholder } from 'components/semantic';
import styles from './index.module.scss';
import { BillData, BillService } from './use-billing';

const LoadingCard = () => {
  return (
    <Card className={styles.card}>
      <Card.Content>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="medium" />
          </Placeholder.Paragraph>
        </Placeholder>
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line length="medium" />
            <Placeholder.Line length="medium" />
          </Placeholder.Paragraph>
        </Placeholder>
      </Card.Content>
    </Card>
  );
};

const ServiceBillCard = memo<{ title: string; value: number }>(({ title, value }) => {
  return (
    <Card className={styles.card}>
      <Card.Content>
        <p>{title}</p>
        <p className={styles.content}>
          <span className="spec-value">{formatMoney(value)}</span> {currency}
        </p>
      </Card.Content>
    </Card>
  );
});

export const getSortedServices = (data: BillData['serviceDetail']) => {
  const { Other, Package, ...rest } = data;
  const sortedKeys = _(rest)
    .entries()
    .orderBy([([k, v]) => v], ['desc'])
    .map(([k, v]) => k as BillService)
    .value();
  if (Package) {
    sortedKeys.push('Package');
  }
  if (Other) {
    sortedKeys.push('Other');
  }
  return sortedKeys;
};

export default ({ loading, billData }: { loading: boolean; billData: BillData }) => {
  const { t } = useTranslation();
  const sortedServices = useMemo(() => getSortedServices(billData.serviceDetail), [
    billData.serviceDetail,
  ]);
  return (
    <Card.Group className={styles.group}>
      {loading &&
        Array(4)
          .fill(0)
          .map((v, index) => <LoadingCard key={index} />)}
      {sortedServices.map((service) => {
        return (
          <ServiceBillCard
            key={service}
            title={t(`billing.type.${service}`)}
            value={billData.serviceDetail[service] || 0}
          />
        );
      })}
    </Card.Group>
  );
};
