// custom util hooks
/**
 * custom hook util
 */
import { useState, useCallback } from 'react';
import { useInterval } from 'react-use';

// pass in length of cool down in sec
export const useCountDown = (timerCount = 60) => {
  const [count, setCount] = useState(0);
  const intervalFn = useCallback(() => {
    setCount((pre) => pre - 1);
  }, []);
  const start = useCallback((value?: number) => setCount(value || timerCount), [timerCount]);
  const stop = useCallback(() => setCount(0), []);
  useInterval(intervalFn, count > 0 ? 1000 : null);
  return [count, { start, stop }] as const;
};
