import React, { memo, useCallback, useRef } from 'react';
import { User, RegionSelect, AppSelect, LocaleSelect, Help, Header } from 'components/Header';
import { HomeLink, HomeLinkProps } from 'components/HomeLink';
import * as layoutStyles from 'components/Layout';
import Page, { PageContainer } from 'components/Page';
import { useIsTablet } from 'utils/media';
import { useUser } from 'App/User';
import AppList from './AppList';
import Finance from './Finance';
import styles from './index.module.scss';
import News from './News';

export const AppListHeader = memo(
  ({ onHomeLinkClick }: { onHomeLinkClick?: HomeLinkProps['onClick'] }) => {
    const [user] = useUser();
    const isTablet = useIsTablet();
    return (
      <Header
        className={styles.headerRight}
        homeLink={
          <HomeLink
            className={styles.homeLink}
            content={isTablet ? 'LeanCloud' : 'L'}
            onClick={onHomeLinkClick}
          />
        }
      >
        <RegionSelect />
        {isTablet && <AppSelect />}
        <span className="space" />
        <LocaleSelect />
        <Help />
        {user && <User user={user} />}
      </Header>
    );
  }
);

export default () => {
  const pageRef = useRef<HTMLDivElement>(null);
  const scrollPageToTop = useCallback(() => pageRef.current && (pageRef.current.scrollTop = 0), []);
  return (
    <div className={layoutStyles.main}>
      <AppListHeader onHomeLinkClick={scrollPageToTop} />
      <Page className={layoutStyles.content} ref={pageRef}>
        <PageContainer className={styles.container}>
          <div className={styles.left}>
            <AppList />
          </div>
          <div className={styles.right}>
            <Finance />
            <News />
          </div>
        </PageContainer>
      </Page>
    </div>
  );
};
