import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, Redirect, RouteComponentProps } from 'react-router-dom';
import { getRegionalConfig } from 'config';
import { printInvoiceEnabled } from 'config/feature-flags';
import AuthorizedRoute from 'components/AuthorizedRoute';
import Loadable from 'components/Loadable';
import { Button } from 'components/semantic';
import Account from './Account';
import Application from './Application';
import AppList from './AppList';
import Notifications from './Notifications';
import NotFound from './Others/NotFound';
import { CombinationHeader } from './Others/utils';
import { withIDVerify } from './utils';

const SignIn = Loadable(() => import('./Sign/SignIn'));
const SignInAuth = Loadable(() => import('./Sign/SignInAuth'));
const Signup = Loadable(() => import('./Sign/SignUp'));
const Authorize = Loadable(() => import('./Sign/Authorize'));
const ResetPassword = Loadable(() => import('./Sign/RestPassword'));
const AppTransfer = Loadable(() => import('./Others/AppTransfer'));
const TwoFARecovery = Loadable(() => import('./Others/2FARecovery'));
const Invoice = Loadable(() => import('./Others/Invoice'));
const DisableEmail = Loadable(() => import('./DisableEmail'));

const NotFoundPage = () => {
  const [t] = useTranslation();

  return (
    <>
      <CombinationHeader />
      <NotFound
        operations={
          <Button as="a" content={t('page.notFount.backHome')} primary href="/" size="big" />
        }
      />
    </>
  );
};

export const Routes = () => (
  <Switch>
    <Route path="/login" exact component={SignIn} />
    <Route path="/login/auth" component={SignInAuth} />
    <Route path="/register" component={Signup} />
    <Route path="/reset-password" component={ResetPassword} />
    <Route path="/email/unsubscribe" component={DisableEmail} />
    <AuthorizedRoute path="/authorize" component={Authorize} />
    <AuthorizedRoute
      path="/apps"
      component={withIDVerify(({ match }: RouteComponentProps) => (
        <Switch>
          <Route path={match.path} component={AppList} exact />
          <Route path={`${match.path}/:appId`} component={Application} />
        </Switch>
      ))}
    />
    <AuthorizedRoute path="/notifications" component={withIDVerify(Notifications)} />
    <AuthorizedRoute path={['/account', '/pay_callback.html']} component={Account} />
    <AuthorizedRoute
      path={['/app-transfer', '/app_transfer.html', '/dashboard/app_transfer.html']}
      component={AppTransfer}
    />
    {printInvoiceEnabled && <AuthorizedRoute path="/invoices/:month" component={Invoice} />}
    <Route path="/2fa-recovery" component={TwoFARecovery} />
    <Redirect push={false} from="/" to="/apps" exact />

    <Redirect push={false} from="/applist" to="/apps" exact />
    <Redirect push={false} from="/applist.html" to="/apps" exact />
    <Route
      path={[
        '/verify.html',
        '/reset.html',
        '/verify_client.html',
        '/reset_client_password.html',
        '/reset_client_email.html',
        '/reset_client_phone.html',
        '/dashboard/verify.html',
        '/dashboard/reset.html',
        '/dashboard/verify_client.html',
        '/dashboard/reset_client_password.html',
        '/dashboard/reset_client_email.html',
        '/dashboard/reset_client_phone.html',
      ]}
      render={() => {
        window.location.href = window.location.href.replace(
          /^https?:\/\/[^/]+(\/dashboard)?/i,
          '/classic'
        );
        return 'Redirecting';
      }}
    />
    <Route
      path="/docs"
      render={() => {
        const docDomain = getRegionalConfig({
          'cn-n1': 'https://docs.leancloud.cn/',
          'cn-rnd': 'https://docs.leancloud.cn/',
          'cn-e1': 'https://docs.leancloud.cn/',
          'us-w1': 'https://docs.leancloud.cn',
          'cn-tds1': '',
          'ap-sg': '',
        });
        window.location.href = window.location.href.replace(/^.*\/docs/i, docDomain);
        return 'Redirecting';
      }}
    />
    <Route component={NotFoundPage} />
  </Switch>
);
