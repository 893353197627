import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from 'components/Page';

export default memo(({ title, path }: { title: string; path: string }) => {
  const { t } = useTranslation();
  const signal = t('label.name');
  return (
    <Page title={title}>
      <iframe key={signal} title={title} src={`/classic${path}`} />
    </Page>
  );
});
