import React, { useMemo, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Page, { PageContainer } from 'components/Page';
import Panel from 'components/Panel';
import { Label, Checkbox, Message, Button } from 'components/semantic';
import { toast } from 'components/Toast';
import { useCountDown } from 'utils/hooks';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import { withTracker } from 'utils/tracker';
import { useUser } from 'App/User';
import styles from './index.module.scss';

export default withTracker(() => {
  const { t } = useTranslation();
  const [user, { update }] = useUser();
  const [countdown, { start, stop }] = useCountDown();
  const isAcceptEmail = useMemo(() => user && user.acceptEmail, [user]);

  const contactUs = useMemo(() => {
    return (
      <Trans
        i18nKey="account.email.notReceive"
        components={{
          Link: (
            <a
              href={`mailto://leancloud-support@xd.com?subject=${t(
                'account.email.notReceive.subject',
                {
                  email: user?.email,
                }
              )}`}
            >
              contact us
            </a>
          ),
        }}
      />
    );
  }, [t, user]);

  const changeEmail = useCallback(async () => {
    try {
      start();
      await request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/changeEmail`, {
        method: 'POST',
      });
      toast.success(
        <>
          {t('account.email.reset.successfully')} {contactUs}
        </>,
        undefined,
        {
          autoClose: false,
          closeOnClick: false,
        }
      );
    } catch (error) {
      stop();
      toast.error(t('account.email.reset.failed'), error);
    }
  }, [t, start, stop, contactUs]);

  const verifyEmail = useCallback(async () => {
    try {
      start();
      await request(`/client-center/${CLIENT_CENTER_VERSION}/requestClientEmailVerify`);
      toast.success(
        <>
          {t('account.email.verify.successfully')} {contactUs}
        </>,
        undefined,
        {
          autoClose: false,
          closeOnClick: false,
        }
      );
    } catch (error) {
      stop();
      toast.error(t('account.email.verify.failed'), error);
    }
  }, [t, start, stop, contactUs]);

  const updateAcceptEmail = useCallback(
    async (isAccept: boolean) => {
      try {
        await request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self`, {
          method: 'POST',
          body: {
            acceptEmail: isAccept,
          },
        });
        update({
          acceptEmail: isAccept,
        });
        toast.success(t('action.set.successfully'));
      } catch (error) {
        toast.error(t('action.set.failed'), error);
      }
    },
    [update, t]
  );

  const verified = user && user.verifiedEmail;
  return (
    <Page title={t('account.settings.email')}>
      <PageContainer>
        <Panel title={t('account.email.current')}>
          <span className={styles.email}>{user?.email}</span>
          {verified && (
            <>
              <Label content={t('label.verified')} color="green" />{' '}
              <Button
                content={
                  countdown > 0
                    ? t('account.email.timer', { count: countdown })
                    : t('action.modify')
                }
                size="small"
                onClick={changeEmail}
                disabled={countdown > 0}
              />
            </>
          )}
          {!verified && (
            <>
              <Label content={t('label.unverified')} color="orange" />{' '}
              <Button
                content={
                  countdown > 0
                    ? t('account.email.timer', { count: countdown })
                    : t('account.email.verify')
                }
                primary
                size="small"
                disabled={countdown > 0}
                onClick={verifyEmail}
              />
              <Message info>{contactUs}</Message>
            </>
          )}
        </Panel>
        <Panel title={t('account.email.preferences')}>
          <Checkbox
            label={t('account.email.preferences.receive')}
            checked={isAcceptEmail}
            onChange={(e, data) => updateAcceptEmail(!!data.checked)}
          />
        </Panel>
      </PageContainer>
    </Page>
  );
});
