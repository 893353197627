import { API_VERSION } from 'utils/request';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import Page, { PageContainer } from 'components/Page';
import { Message, Loader, Button, LinkButton } from 'components/semantic';
import { withTracker } from 'utils/tracker';
import { useAPI, APIError } from 'utils/use-api';
import useUrlSearchParam from 'utils/use-url-search-param';
import { InvoiceAvailable } from 'config/feature-flags';
interface Order {
  id: string;
  status: 1 | 0;
  created: string;
  amount: number;
  currency: string;
}

export const PayCallback = withTracker(() => {
  const { t } = useTranslation();

  const [chargeId] = useUrlSearchParam('chargeid');
  const [orderNo] = useUrlSearchParam('order_no');
  const [payment, { loading, error, reload }] = useAPI<Order>(
    `/${API_VERSION}/payment/prepaid-order/${chargeId || orderNo}`
  );

  if (loading) {
    return (
      <Page>
        <PageContainer>
          <Loader active inline size="small" />
        </PageContainer>
      </Page>
    );
  }

  if (error) {
    return (
      <Page>
        <PageContainer>
          <APIError message={t('finance.pay.error')} error={error} retry={reload} />
        </PageContainer>
      </Page>
    );
  }

  if (payment) {
    const { id, amount, currency, created, status } = payment;
    let content: React.ReactNode;

    if (!id) {
      content = (
        <APIError message={t('finance.pay.error')} error={new Error(t('finance.pay.noOrder'))} />
      );
    }
    if (status === 1) {
      content = (
        <Message
          info
          icon="piggy bank"
          header={t('finance.pay.succeeded')}
          content={
            <>
              <p>
                <Trans
                  i18nKey="finance.pay.details"
                  values={{
                    time: created,
                    amount: amount,
                    currency: currency,
                  }}
                />
                {InvoiceAvailable && <div>{t('finance.pay.invoice.tips')}</div>}
              </p>
              <p>
                <Link to="/account/finance">
                  <Button>{t('finance.pay.backToFinance')}</Button>
                </Link>
                <Link to="/account/finance/payments">
                  <LinkButton>{t('finance.pay.viewHistory')}</LinkButton>
                </Link>
              </p>
            </>
          }
        />
      );
    }
    if (status === 0) {
      content = (
        <Message
          error
          header={t('finance.pay.failed')}
          content={
            <p>
              <Link component={Button} to="/account/finance">
                {t('finance.pay.backToFinance')}
              </Link>
              <Link component={LinkButton} to="mailto://leancloud-support@xd.com">
                {t('action.contactUs')}
              </Link>
            </p>
          }
        />
      );
    }

    return (
      <Page>
        <PageContainer>{content}</PageContainer>
      </Page>
    );
  }

  return null;
});
