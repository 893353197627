import React, { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import request, { API_VERSION, CLIENT_CENTER_VERSION } from 'utils/request';
import Page, { PageContainer } from 'components/Page';
import Panel from 'components/Panel';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Modal, LinkButton } from 'components/semantic';
import { toast } from 'components/Toast';
import { useUser } from 'App/User';
import { consoleAPIServer } from 'config';

import styles from './index.module.scss';

const Password: FC = () => {
  const { t } = useTranslation();

  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [loading, setLoading] = useState(false);
  const updatePassword = async () => {
    if (newPass !== confirmPass) {
      toast.error(t('newPass.notMatch'));
      return;
    }
    try {
      setLoading(true);
      await request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/changePassword`, {
        method: 'POST',
        body: { oldPassword: currentPass, newPassword: newPass },
      });
      toast.success(t('action.update.successfully'));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Panel title={t('updatePass.title')}>
      <Form className={styles.form} onSubmit={updatePassword}>
        <Form.Field required>
          <label htmlFor="currentPass">{t('currentPass.title')}</label>
          <Input
            required
            id="currentPass"
            name="currentPass"
            type="password"
            value={currentPass}
            onChange={(e) => {
              setCurrentPass(e.target.value);
            }}
          />
        </Form.Field>
        <Form.Field required>
          <label htmlFor="newPass">{t('newPass.title')}</label>
          <Input
            required
            minLength="8"
            maxLength="20"
            id="newPass"
            name="newPass"
            type="password"
            value={newPass}
            onChange={(e) => {
              setNewPass(e.target.value);
            }}
          />
        </Form.Field>
        <Form.Field required>
          <label htmlFor="newPassConfirm">{t('newPass.confirm.title')}</label>
          <Input
            required
            minLength="8"
            maxLength="20"
            id="newPassConfirm"
            name="newPassConfirm"
            type="password"
            value={confirmPass}
            onChange={(e) => {
              setConfirmPass(e.target.value);
            }}
          />
        </Form.Field>
        <Button loading={loading} type="submit" content={t('action.update')} />
      </Form>
    </Panel>
  );
};

const FORM_ID = 'open2fa';

const TwoFA = () => {
  const { t } = useTranslation();
  const [user, { reload }] = useUser();
  const [open, setOpen] = useState(false);
  const [opening, setOpening] = useState(false);
  const [recoveryOpen, setRecoveryOpen] = useState(false);
  const [info, setInfo] = useState<{ recovery: string; secret: string }>();
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');

  const isEnable = user?.flags.includes('client-need-2fa');

  const openModal = async () => {
    if (isEnable) {
      setOpen(true);
      return;
    }
    try {
      setOpening(true);
      const res = await request<{ recovery: string; secret: string }>(
        `/client-center/${CLIENT_CENTER_VERSION}/set2fa`,
        {
          method: 'POST',
        }
      );

      setInfo(res);
      setOpen(true);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setOpening(false);
    }
  };

  const enable = async () => {
    try {
      await request<{ recovery: string; secret: string }>(
        `/client-center/${CLIENT_CENTER_VERSION}/set2fa/enable`,
        {
          method: 'POST',
          body: {
            code,
            password,
          },
        }
      );
      setOpen(false);
      setRecoveryOpen(true);
      toast.success(t('2FA.open.successfully'));
    } catch (error) {
      toast.error(t('2FA.open.failed'), error.message);
    }
  };
  const disable = async () => {
    try {
      await request<{ recovery: string; secret: string }>(
        `/client-center/${CLIENT_CENTER_VERSION}/set2fa/disable`,
        {
          method: 'POST',
          body: {
            code,
            password,
          },
        }
      );
      setOpen(false);
      reload();
      toast.success(t('2FA.close.successfully'));
    } catch (error) {
      toast.error(t('2FA.close.fail'), error.message);
    }
  };

  return (
    <Panel title={t('2FA')}>
      <p>{t(`2FA.${isEnable ? 'close' : 'open'}.hint`)}</p>
      <Button
        loading={opening}
        content={t(`2FA.${isEnable ? 'close' : 'open'}`)}
        onClick={openModal}
      />

      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Modal.Header>{t('2FA')}</Modal.Header>
        <Modal.Content>
          <Form
            id={FORM_ID}
            onSubmit={() => {
              !isEnable ? enable() : disable();
            }}
          >
            {!isEnable && (
              <>
                <Form.Field label={`1. ${t('2FA.open.install')}`} />
                <Form.Field
                  label={
                    <label>
                      2.{' '}
                      <Trans
                        i18nKey="2FA.open.add"
                        components={{ code: <code /> }}
                        values={{ secret: info?.secret }}
                      />
                    </label>
                  }
                />
                <img
                  src={`${consoleAPIServer}/${API_VERSION}/qrcode?url=${encodeURIComponent(
                    `otpauth://totp/LeanCloud:${user?.email}?secret=${info?.secret}&issuer=LeanCloud`
                  )}&width=200&height=200`}
                  alt="QR"
                />
              </>
            )}
            <Form.Input
              required
              label={`${isEnable ? '' : '3. '}${t('2FA.open.code')}`}
              value={code}
              pattern="\d{6}"
              onChange={(e) => setCode(e.target.value)}
            />
            {isEnable && (
              <p className="help-block">
                <Trans
                  i18nKey="2FA.tipInfo"
                  components={{ Recovery: <Link to="/2fa-recovery" target="_blank" /> }}
                />
              </p>
            )}
            <Form.Input
              required
              type="password"
              label={`${isEnable ? '' : '4. '}${t('2FA.open.password')}`}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <LinkButton
            onClick={() => {
              setOpen(false);
              setInfo(undefined);
            }}
            content={t('action.cancel')}
          />
          {isEnable && <Button type="submit" form={FORM_ID} content={t('2FA.close')} primary />}
          {!isEnable && <Button type="submit" form={FORM_ID} content={t('2FA.open')} primary />}
        </Modal.Actions>
      </Modal>

      <Modal open={recoveryOpen} closeOnDimmerClick={false}>
        <Modal.Header>{t('2FA.recovery.save')}</Modal.Header>
        <Modal.Content>
          <p>{t('2FA.recovery.hint')}</p>
          <h2>
            <code>{info?.recovery}</code>
          </h2>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content={t('2FA.recovery.saved')}
            primary
            onClick={() => {
              reload();
              setRecoveryOpen(false);
            }}
          />
        </Modal.Actions>
      </Modal>
    </Panel>
  );
};

const Security: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={[t('account.settings.security'), t('account.settings')]}>
      <PageContainer>
        <Password />
        <TwoFA />
      </PageContainer>
    </Page>
  );
};

export default Security;
