/* eslint-disable i18n/no-chinese-character */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { internalUserGiftEnbaled } from 'config/feature-flags';
import ExternalLink from 'components/ExternalLink';
import Hint from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  Label,
  Message,
  Placeholder,
  Popup,
} from 'components/semantic';
import { createEventTracker, withTracker } from 'utils/tracker';
import { APIError, useAPI } from 'utils/use-api';
import { useUser, VerifyState } from 'App/User';
import CompanyVerifyModal from './CompanyVerifyModal';
import { CLIENT_CENTER_VERSION } from 'utils/request';

import styles from './index.module.scss';
import IndividualVerifyModal from './IndividualVerifyModal';

const track = createEventTracker('Verify');

enum ClientType {
  company = 1,
  individual = 0,
}
interface Verification {
  status: VerifyState;
  clientType: ClientType;
  clientName?: string;
  company?: string;
  rejectReason?: string;
}

const VerificationState = ({
  status,
  clientType,
  clientName,
  company,
  rejectReason,
}: Verification) => {
  const isCompany = clientType === ClientType.company;
  let name = null;
  if (clientName || company) {
    name = (
      <p>
        <span className={styles.name}>
          <Popup
            trigger={isCompany ? <Icon name="building" /> : <Icon name="user" />}
            content={isCompany ? '公司' : '个人'}
          />
          {isCompany ? company : clientName}
        </span>
      </p>
    );
  }
  switch (status) {
    case VerifyState.NONE:
      return <Label content="未认证" size="big" basic />;
    case VerifyState.VERIFIED:
      return (
        <>
          {name}
          <div>
            <Label content="已认证" size="big" basic color="green" />
          </div>
        </>
      );
    case VerifyState.REJECTED:
      return (
        <>
          {name}
          <div>
            <Label content="未通过" size="big" basic color="red" />
            {rejectReason && <Hint content={rejectReason} color="red" />}
          </div>
        </>
      );
    case VerifyState.PENDING:
      return (
        <>
          {name}
          <div>
            <Label content="审核中" size="big" basic color="orange" />
          </div>
        </>
      );
    default:
      return <Label size="big" basic content="未知状态" />;
  }
};

export default withTracker(() => {
  const { t } = useTranslation();
  const [user, { update }] = useUser();
  const [verification, { error, loading, reload }] = useAPI<Verification>(
    `/client-center/${CLIENT_CENTER_VERSION}/clients/self/clientVerification`
  );
  const [popupActive, setPopupActive] = useState(false);
  const [individualModalActive, setIndividualModalActive] = useState(false);
  const [companyModalActive, setCompanyModalActive] = useState(false);

  const onIndividualVerifySucceeded = useCallback(() => {
    setIndividualModalActive(false);
    reload();
    update({
      clientVerification: VerifyState.VERIFIED,
    });
  }, [reload, update]);
  const onCompanyVerifySucceeded = useCallback(() => {
    setCompanyModalActive(false);
    reload();
    update({
      clientVerification: VerifyState.PENDING,
    });
  }, [reload, update]);

  const verified = verification && verification.status === VerifyState.VERIFIED;
  const individualVerified = verified && verification!.clientType === ClientType.individual;

  const isPotentialInternalUser = internalUserGiftEnbaled && user?.email.indexOf('@xd.com') !== -1;

  return (
    <Page title={t('account.settings.verify')}>
      <PageContainer>
        {error && <APIError error={error} message="获取账户实名认证状态失败" retry={reload} />}
        {loading ? (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        ) : (
          <>
            {!verified &&
              (isPotentialInternalUser ? (
                <Message
                  info
                  icon="mail"
                  header={`验证邮箱 ${user?.email}`}
                  content={
                    <Message.Content>
                      <span role="img" aria-label="Hi">
                        👋
                      </span>{' '}
                      {user?.username}，欢迎使用
                      LeanCloud。在开始之前，我们需要先通过邮箱验证你的身份（并激活{' '}
                      <ExternalLink href="https://confluence.xindong.com/pages/viewpage.action?pageId=95738706">
                        一份特殊的小礼物
                      </ExternalLink>
                      ）。
                    </Message.Content>
                  }
                />
              ) : (
                <Message
                  info
                  header="请先完成实名认证"
                  content="根据《中华人民共和国网络安全法》的要求，使用 LeanCloud 服务的用户必须进行实名认证。未进行实名认证的账号无法使用各项服务。"
                />
              ))}
            {verification && <VerificationState {...verification} />}
            {(individualVerified || !verified) && <Divider />}

            {individualVerified && (
              <Button
                content="转为企业认证"
                onClick={() => {
                  setCompanyModalActive(true);
                  track('Verify button click', {
                    label: 'company',
                  });
                }}
              />
            )}

            {!(verified || isPotentialInternalUser) && (
              <Popup
                on="click"
                wide
                hoverable
                inverted={false}
                position="bottom left"
                open={popupActive}
                onOpen={() => setPopupActive(true)}
                onClose={() => setPopupActive(false)}
                trigger={<Button primary content="开始认证" />}
              >
                <Grid divided columns={2}>
                  <Grid.Column textAlign="center">
                    <Header icon>
                      <Icon name="user" circular />
                      个人认证
                    </Header>
                    <p>
                      <Button
                        content="认证"
                        onClick={() => {
                          setPopupActive(false);
                          setIndividualModalActive(true);
                          track('Verify button click', {
                            label: 'individual',
                          });
                        }}
                      />
                    </p>
                  </Grid.Column>
                  <Grid.Column textAlign="center">
                    <Header icon>
                      <Icon name="building" circular />
                      企业认证
                    </Header>
                    <p>
                      <Button
                        content="认证"
                        onClick={() => {
                          setPopupActive(false);
                          setCompanyModalActive(true);
                          track('Verify button click', {
                            label: 'company',
                          });
                        }}
                      />
                    </p>
                  </Grid.Column>
                  <Message attached="bottom" info>
                    个人与企业认证的账号将获得同样的功能支持。但如果违反了中国大陆法规，承担责任的主体是不同的。
                  </Message>
                </Grid>
              </Popup>
            )}
            <IndividualVerifyModal
              modalProps={{
                open: individualModalActive,
                onClose: () => setIndividualModalActive(false),
              }}
              onSucceeded={onIndividualVerifySucceeded}
            />
            <CompanyVerifyModal
              modalProps={{
                open: companyModalActive,
                onClose: () => setCompanyModalActive(false),
              }}
              onSucceeded={onCompanyVerifySucceeded}
            />
          </>
        )}
      </PageContainer>
    </Page>
  );
});
