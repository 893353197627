/* eslint-disable i18n/no-chinese-character */
import { useTranslation } from 'react-i18next';
import { ICPBeianConfigEnabled } from 'config/feature-flags';
import { ErrorPage } from 'components/Page';
import { Button, LinkButton } from 'components/semantic';
import { withTracker } from 'utils/tracker';

export default withTracker(() => {
  const { t } = useTranslation();
  return (
    <ErrorPage
      centered={false}
      message={t('account.settings.beian')}
      explaination={
        <div>
          <p>根据工信部规定，在 LeanCloud 绑定的文件域名、云引擎域名、API 域名，都需要备案。</p>
          <p>
            如果你的主域名是通过其他云服务商新增备案，那么你在 LeanCloud 控制台绑定 API
            域名或云引擎域名后，需要在 LeanCloud 接入备案。 由于 CDN
            服务一般不需要接入备案，因此，如果该子域名仅用于 LeanCloud 文件域名，那么无需在
            LeanCloud 接入备案。
          </p>
          <p>
            {ICPBeianConfigEnabled
              ? '如有需要，'
              : '由于腾讯云机房限制暂不支持通过我们以用户主体身份提交备案资料。希望在华东节点所在机房做备案接入或直接办理新备案的商用版用户，'}
            请使用工单联系我们，
            {ICPBeianConfigEnabled
              ? '选择「域名与备案」分类下合适的备案类型并提供所需的资料，'
              : '选择「域名与备案 - 申请华东节点备案授权码」分类，'}
            我们会协助你完成备案。
          </p>
        </div>
      }
      operations={
        <>
          <Button
            as="a"
            content={t('提交工单')}
            primary
            href="https://www.leanticket.cn/tickets/new"
            size="big"
          />
          <LinkButton
            href="https://www.leanticket.cn/tickets?categoryId=5d8db50112215f0072a7d35d"
            content="查看备案工单"
          />
        </>
      }
    />
  );
});
