import React, { useMemo, memo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { currency } from 'config';
import { printInvoiceEnabled } from 'config/feature-flags';
import _ from 'lodash';
import moment from 'moment';
import { MomentTimeFormat, formatTime, formatMoney } from 'utils';
import { DateInput } from 'components/DateTime';
import Page, { PageContainer } from 'components/Page';
import { Icon, InlineDivider} from 'components/semantic';
import { APIError } from 'utils/use-api';
import useURLSearchParam from 'utils/use-url-search-param';
import styles from './index.module.scss';
import MonthDetail from './MonthDetail';
import MonthService from './MonthService';
import useBilling from './use-billing';


const Total = memo<{ total: number }>(({ total }) => {
  return (
    <div>
      <span className={`spec-value ${styles.totalAmount}`}>
        {formatMoney(Number(total.toFixed(2)))}
      </span>{' '}
      {currency}
    </div>
  );
});

const maxDate = moment().subtract(1, 'month').toDate();

const Billing = () => {
  const { t } = useTranslation();
  const [queryTime, setQueryTime] = useURLSearchParam('month');
  const date = useMemo(() => (queryTime ? moment(queryTime).toDate() : maxDate), [queryTime]);
  const monthString = useMemo(() => formatTime(date, MomentTimeFormat.QUERY_MONTH), [date]);
  const [billData, { error, loading }] = useBilling(monthString);
  const total = useMemo(() => _.sumBy(billData.appDetail, 'amount'), [billData]);
  return (
    <Page>
      <PageContainer fixed>
        <div className={styles.toolbar}>
          <DateInput
            format={MomentTimeFormat.DISPLAY_MONTH}
            value={date}
            modes={['year', 'month']}
            placeholder={t('label.pickTime')}
            onChange={({ value }) => {
              if (!value) {
                setQueryTime(value);
              } else {
                setQueryTime(formatTime(value, MomentTimeFormat.DISPLAY_MONTH));
              }
            }}
            maxDate={maxDate}
          />
          <InlineDivider />
          <Trans
            i18nKey="billing.total"
            components={{
              Total: <Total total={total} />,
            }}
          />
          <InlineDivider />
          <Link to="/account/billing/details">{t('billing.dailyBreakdown')}</Link>
          {printInvoiceEnabled && (
            <>
              <InlineDivider />
              <Link to={`/invoices/${monthString}`} target="_blank" rel="noopener noreferrer">
                <Icon name="print" />
                {t('billing.print')}
              </Link>
            </>
          )}
        </div>
        {error && <APIError error={error} message={t('billing.fetchError')} />}
        <MonthService billData={billData} loading={loading} />
        <MonthDetail billData={billData} loading={loading} />
      </PageContainer>
    </Page>
  );
};

export default Billing;
