import React, { memo } from 'react';
import classNames from 'classnames';

import {
  Header,
  Help,
  LocaleSelect,
  RegionSelect,
  styles as headerStyles,
  User,
} from 'components/Header';
import { HomeLink } from 'components/HomeLink';
import { useUser } from 'App/User';
import styles from './index.module.scss';

export const CombinationHeader = memo(() => {
  const [user] = useUser();
  return (
    <Header>
      <div className={styles.left}>
        <RegionSelect />
      </div>
      <HomeLink
        transparent
        className={classNames(headerStyles.menuItem, headerStyles.menuItemTrigger)}
      />
      <div className={styles.right}>
        <LocaleSelect />
        <Help />
        {user && <User user={user} />}
      </div>
    </Header>
  );
});
