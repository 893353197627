import { useAPI, useLocalState } from 'utils/use-api';
import { CLIENT_CENTER_VERSION } from 'utils/request';

export interface UserDetail {
  address?: string;
  clientId: number;
  clientName: string;
  clientType: number;
  company?: string;
  companySite: string;
  companySize?: number;
  created: string;
  id: number;
  invoiceTitle?: string;
  needInvoice: number;
  oicq: string;
  phone: string;
}
export const useUserDetail = () => {
  return useLocalState(
    useAPI<UserDetail>(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/detail`)
  );
};
