import { useTransform } from '@leancloud/use-resource';
import _ from 'lodash';
import { useAPI } from 'utils/use-api';
import { PricingServiceType } from 'App/Pricing';
import { BILLING_CENTER_PREFIX } from 'utils/request';

interface BillInfo {
  amount: number;
  app_id: string;
  app_name: string;
  client_id: number;
  month: string;
  currency: string;
}

interface RawBillData extends BillInfo {
  services: Array<{
    amount: number;
    service: PricingServiceType;
  }>;
}
export type BillService =
  | 'Storage'
  | 'LeanEngine'
  | 'Play'
  | 'RTM'
  | 'Push'
  | 'SMS'
  | 'Package'
  | 'Other';
export type BillData = {
  appDetail: Array<
    BillInfo & {
      services: {
        [key in BillService]?: number;
      };
    }
  >;
  serviceDetail: {
    [key in BillService]?: number;
  };
};

const getService = (data: RawBillData['services']) => {
  return data.map(({ service, amount }) => {
    if (['RTM', 'Push', 'SMS', 'Package'].includes(service)) {
      return {
        amount,
        service,
      };
    }
    if (['API', 's3', 'qcloud', 'qiniu', 'LiveQuery', 'Search'].includes(service)) {
      return {
        amount,
        service: 'Storage',
      };
    }
    if (['LeanCache', 'LeanDB', 'LeanEngine', 'RDB'].includes(service)) {
      return {
        amount,
        service: 'LeanEngine',
      };
    }
    if (['LeaderBoard', 'MultiPlayer', 'ClientEngine'].includes(service)) {
      return {
        amount,
        service: 'Play',
      };
    }
    return {
      amount,
      service: 'Other',
    };
  });
};

const transformBillData = (data?: { results: RawBillData[] }): BillData => {
  if (!data) {
    return {
      appDetail: [],
      serviceDetail: {},
    };
  }
  const appDetail = data.results.map(({ services, ...rest }) => {
    const tmpServices = getService(services);
    const obj = tmpServices.reduce((pre, curr) => {
      pre[curr.service] = pre[curr.service] ? pre[curr.service]! + curr.amount : curr.amount;
      return pre;
    }, {} as { [key in BillService]?: number });
    return {
      ...rest,
      services: obj,
    };
  });

  const serviceDetail = appDetail.reduce((pre, curr) => {
    const { services } = curr;
    return _.mergeWith(pre, services, (objValue, srcValue) => {
      if (objValue && srcValue) {
        return Math.round((objValue + srcValue) * 100) / 100;
      }
      return objValue || srcValue;
    });
  }, {} as { [key in BillService]?: number });

  return {
    appDetail,
    serviceDetail,
  };
};

const useBilling = (month: string) => {
  return useTransform(
    useAPI<{ results: RawBillData[] }>(
      `${BILLING_CENTER_PREFIX}/clients/self/monthly-bills/${month}`,
      undefined,
      [month]
    ),
    transformBillData
  );
};

export default useBilling;
