import {
  FC,
  useRef,
  Dispatch,
  SetStateAction,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { useTranslation, Trans } from 'react-i18next';
import request, { CLIENT_CENTER_VERSION, API_VERSION } from 'utils/request';
import { useUser } from 'App/User';
import { Button, Modal, LinkButton, Form, Input, Label } from 'components/semantic';
import { useCountDown } from 'utils/hooks';
import { toast } from 'components/Toast';
import { Message } from 'components/semantic';

const UnVerified = () => {
  const { t } = useTranslation();
  const [user, { reload }] = useUser();
  const [code, setCode] = useState('');
  const [countdown, { start, stop }] = useCountDown();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    request(`/${API_VERSION}/verifyClientPhone/${code}`)
      .then(() => {
        setOpen(false);
        reload();
        toast.success(t('action.verify.successful'));
      })
      .catch((error) => toast.success(t('action.verify.successful', error.messageF)))
      .finally(() => setLoading(false));
  };

  const getCode = () => {
    start();
    request(`/${API_VERSION}/requestClientPhoneVerify`)
      .then(() => {
        toast.success(t('action.verify.getCode.successfully'));
      })
      .catch((error) => {
        stop();
        toast.error(t('action.verify.getCode.failed'), error.message);
      });
  };

  return (
    <span>
      <Label as={'span'} size="mini" color="orange">
        {t('label.unverified')}
      </Label>{' '}
      <Button size="mini" content={t('action.verify')} onClick={() => setOpen(true)} />
      <SetPhone />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Trans
            i18nKey="profile.verifyPhoneNumber"
            components={{ code: <code /> }}
            values={{ phone: user?.phone }}
          />
        </Modal.Header>
        <Modal.Content>
          <Form id="code" onSubmit={onSubmit}>
            <Form.Field required>
              <label htmlFor="code">{t('sms.code')}</label>
              <Input
                id="code"
                label={
                  <Button onClick={getCode} disabled={countdown > 0}>
                    {countdown > 0 ? countdown : t('action.verify.getCode')}
                  </Button>
                }
                labelPosition="right"
                placeholder="123456"
                pattern="\d{6}"
                value={code}
                onChange={(e, data) => {
                  setCode(data.value);
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <LinkButton content={t('action.cancel')} onClick={() => setOpen(false)} />
          <Button
            primary
            disabled={!code || loading}
            loading={loading}
            content={t('action.verify')}
            type="submit"
            form="code"
          />
        </Modal.Actions>
      </Modal>
    </span>
  );
};

type SetPhoneHandler = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const SetPhone = forwardRef<SetPhoneHandler, { hideButton?: boolean; token?: string }>(
  ({ hideButton = false, token }, ref) => {
    const { t } = useTranslation();
    const [user, { reload }] = useUser();
    const [code, setCode] = useState('');
    const [phone, setPhone] = useState('');
    const [countdown, { start, stop }] = useCountDown();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({ setOpen }));

    const onSubmit = () => {
      setLoading(true);
      request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/changePhoneForClient`, {
        method: 'POST',
        body: {
          code,
          phone,
        },
      })
        .then(() => {
          toast.success(t('action.set.successfully'));
          setOpen(false);
          reload();
        })
        .catch((error) => {
          toast.error(t('action.set.failed'), error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const getCode = () => {
      start();
      request(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/requestChangeClientPhoneSmsCode`,
        {
          query: { phone, token },
        }
      )
        .then(() => {
          toast.success(t('action.verify.getCode.successfully'));
        })
        .catch((error) => {
          stop();
          toast.error(t('action.verify.getCode.failed'), error.message);
        });
    };

    return (
      <>
        {!hideButton && (
          <Button
            size="mini"
            content={user?.phone ? t('action.modify') : t('action.set')}
            onClick={() => setOpen(true)}
          />
        )}

        <Modal open={open} onClose={() => setOpen(false)}>
          <Modal.Header>{t('profile.phone.set')}</Modal.Header>
          <Modal.Content>
            <Form id="setPhone" onSubmit={onSubmit}>
              <Form.Input
                required
                label={t('label.mobilePhoneNumber')}
                value={phone}
                onChange={(e, data) => {
                  setPhone(data.value);
                }}
              />
              <Form.Field required>
                <label htmlFor="code">{t('sms.code')}</label>
                <Input
                  id="code"
                  label={
                    <Button onClick={getCode} disabled={countdown > 0 || !phone}>
                      {countdown > 0 ? countdown : t('action.verify.getCode')}
                    </Button>
                  }
                  labelPosition="right"
                  placeholder="123456"
                  pattern="\d{6}"
                  value={code}
                  onChange={(e, data) => {
                    setCode(data.value);
                  }}
                />
              </Form.Field>
            </Form>
            <Message info content={t('profile.phone.set.hint')} />
          </Modal.Content>
          <Modal.Actions>
            <LinkButton content={t('action.cancel')} onClick={() => setOpen(false)} />
            <Button
              primary
              loading={loading}
              disabled={loading}
              content={t('action.set')}
              type="submit"
              form="setPhone"
            />
          </Modal.Actions>
        </Modal>
      </>
    );
  }
);

const Verified = () => {
  const { t } = useTranslation();
  const [user] = useUser();
  const [code, setCode] = useState('');
  const [countdown, { start, stop }] = useCountDown();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const setPhoneRef = useRef<SetPhoneHandler>(null);
  const [token, setToken] = useState('');

  if (!user) {
    return null;
  }
  const { email, phone } = user;

  const onSubmit = () => {
    setLoading(true);
    request<{ token: string }>(
      `/client-center/${CLIENT_CENTER_VERSION}/clients/:clientId/verifyClientOldPhone/${code}`,
      {}
    )
      .then((res) => {
        setOpen(false);
        setToken(res.token);
        setPhoneRef.current?.setOpen(true);
      })
      .catch((error) => toast.success(t('action.verify.successful', error.messageF)))
      .finally(() => {
        setLoading(false);
        setOpen(false);
        setPhoneRef.current?.setOpen(true);
      });
  };

  const getCode = () => {
    start();
    request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/requestVerifyClientOldPhone`, {
      query: { phone },
    })
      .then(() => {
        toast.success(t('action.verify.getCode.successfully'));
      })
      .catch((error) => {
        stop();
        toast.error(t('action.verify.getCode.failed'), error.message);
      });
  };

  const resetPhoneByMail = () => {
    request(`/client-center/${CLIENT_CENTER_VERSION}/resetPhoneForClient`, { method: 'POST' })
      .then(() => {
        setOpen(false);
        toast.success(t('profile.resetPhone.successfully', { email }));
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <>
      <Label as={'span'} size="mini" color="green">
        {t('label.verified')}
      </Label>{' '}
      <Button
        size="mini"
        content={user?.phone ? t('action.modify') : t('action.set')}
        onClick={() => setOpen(true)}
      />
      <Modal open={open} onClose={() => setOpen(false)}>
        <Modal.Header>
          <Trans
            i18nKey="profile.verifyOldPhoneNumber"
            components={{ code: <code /> }}
            values={{ phone: user?.phone }}
          />
        </Modal.Header>
        <Modal.Content>
          <Form id="check" onSubmit={onSubmit}>
            <Form.Field required>
              <label htmlFor="code">{t('sms.code')}</label>
              <Input
                required
                id="code"
                label={
                  <Button onClick={getCode} disabled={countdown > 0}>
                    {countdown > 0 ? countdown : t('action.verify.getCode')}
                  </Button>
                }
                labelPosition="right"
                placeholder="123456"
                pattern="\d{6}"
                value={code}
                onChange={(e, data) => {
                  setCode(data.value);
                }}
              />
            </Form.Field>
          </Form>
          <Message
            content={
              <Trans
                i18nKey="profile.verifyOldPhoneNumber.hint"
                components={{
                  Link: <LinkButton onClick={resetPhoneByMail} />,
                }}
                values={{ phone: user?.phone }}
              />
            }
          />
        </Modal.Content>
        <Modal.Actions>
          <LinkButton content={t('action.cancel')} onClick={() => setOpen(false)} />
          <Button
            primary
            disabled={!code || loading}
            loading={loading}
            content={t('action.verify')}
            type="submit"
            form="check"
          />
        </Modal.Actions>
      </Modal>
      <SetPhone ref={setPhoneRef} token={token} hideButton />
    </>
  );
};

const Phone: FC = () => {
  const [user] = useUser();

  if (!user) {
    return null;
  }

  const { verifiedPhone, phone } = user;

  if (!phone) {
    return <SetPhone />;
  }

  if (!verifiedPhone) {
    return <UnVerified />;
  }

  return <Verified />;
};

export default Phone;
