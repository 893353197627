import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import copy from 'clipboard-copy';
import { Button, Popup, SemanticSIZES } from 'components/semantic';
import styles from './index.module.scss';

enum State {
  initial,
  done,
  failed,
}
const Icons = {
  [State.initial]: 'copy outline',
  [State.done]: 'check',
  [State.failed]: 'exclamation',
};

export const CopiableText = ({
  text,
  content = text,
  className,
  size = 'mini',
}: {
  text: string;
  content?: ReactNode;
  className?: string;
  size?: SemanticSIZES;
}) => {
  const { t } = useTranslation();
  const getInitialState = useCallback(
    (): [State, string] => [State.initial, t('action.copy')],
    [t]
  );
  const [[state, hint], setCopyState] = useState(getInitialState);
  useEffect(() => setCopyState(getInitialState), [content, getInitialState]);
  return (
    <span className={className}>
      {content}{' '}
      <Popup
        trigger={
          <Button
            type="button"
            icon={Icons[state]}
            size={size}
            onClick={(e) => {
              copy(text)
                .then(() => setCopyState([State.done, t('action.copy.done')]))
                .catch((error) =>
                  setCopyState([State.failed, `${t('action.copy.failed')}${error.message}`])
                );
              e.stopPropagation();
            }}
          />
        }
        content={hint}
      />
    </span>
  );
};

export default CopiableText;

export const CopiableLink = ({ url, linkClassName }: { url: string; linkClassName?: string }) => (
  <CopiableText
    text={url}
    content={
      <a href={url} title={url} target="_blank" rel="noopener noreferrer" className={linkClassName}>
        {url}
      </a>
    }
  />
);

export const CopiableCode = ({
  code,
  content = <code className={styles.code}>{code}</code>,
  className,
}: {
  code: string;
  content?: ReactNode;
  className?: string;
}) => (
  <CopiableText text={code} content={content} className={className || styles.credentialValue} />
);
