import React, { useMemo, useState, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { currency } from 'config';
import _ from 'lodash';
import { formatMoney } from 'utils';
import { NoDataRow } from 'components/NoData';
import { Table, Popup, Icon, TableCellProps } from 'components/semantic';
import { SortPieView } from 'components/StatisticView';
import styles from './index.module.scss';
import { getSortedServices } from './MonthService';
import { BillData, BillService } from './use-billing';

const Amount = ({ value }: { value?: number }) => {
  if (value) {
    return (
      <>
        <span>{`${value ? formatMoney(value) : '-'}`}</span> {currency}
      </>
    );
  }
  return <>-</>;
};
const CurrencyCell = memo<{ content?: number } & TableCellProps>(({ content, ...rest }) => (
  <Table.Cell singleLine textAlign="right" {...rest} content={<Amount value={content} />} />
));

const DisplayAppTableChart = ({
  data,
  title,
}: {
  data: [string, number][];
  title?: React.ReactNode;
}) => {
  return (
    <Popup.Content className={styles.piePopupContent}>
      {title && <h4 className={styles.title}>{title}</h4>}
      <SortPieView innerRadius={120} data={data} tooltipFormat={formatMoney} />
    </Popup.Content>
  );
};

const DisplayTableRow = memo<{
  appName: string;
  amount: number;
  displayServices: BillService[];
  services: {
    [key in BillService]?: number;
  };
}>(({ appName, amount, services, displayServices }) => {
  const { t } = useTranslation();
  const chartData = Object.entries(services).map(
    ([k, v]) => [t(`billing.type.${k}`), v || 0] as [string, number]
  );
  return (
    <Table.Row>
      <Table.Cell content={appName} />
      <Table.Cell singleLine textAlign="right">
        {amount > 0 && (
          <Popup
            hoverable
            inverted={false}
            position="right center"
            content={<DisplayAppTableChart data={chartData} title={appName} />}
            trigger={
              <span className={styles.piePopup}>
                <Amount value={amount} />
                <span className={styles.pieChart}>
                  <Icon name="chart pie" />
                </span>
              </span>
            }
          />
        )}
      </Table.Cell>
      {displayServices.map((service) => (
        <CurrencyCell content={services[service]} key={service} />
      ))}
    </Table.Row>
  );
});

export default ({ loading, billData }: { loading: boolean; billData: BillData }) => {
  const { t } = useTranslation();
  const [sortRule, setSortRule] = useState<{
    key: string;
    rule: 'descending' | 'ascending';
  }>({
    key: 'amount',
    rule: 'descending',
  });
  const { appDetail, serviceDetail } = billData;
  const displayServices = useMemo(() => getSortedServices(serviceDetail), [serviceDetail]);

  const sort = useCallback((key: string) => {
    setSortRule((pre) => {
      return {
        key,
        rule: pre.key === key && pre.rule === 'descending' ? 'ascending' : 'descending',
      };
    });
  }, []);

  const sortedData = useMemo(
    () =>
      _.orderBy(
        appDetail,
        [
          (data) => {
            if (sortRule.key === 'amount') {
              return data.amount;
            }
            return data.services[sortRule.key] || -1;
          },
        ],
        [sortRule.rule === 'ascending' ? 'asc' : 'desc']
      ),
    [appDetail, sortRule]
  );

  const groupServiceData = useMemo(() => {
    return appDetail.reduce(
      (pre, curr) => {
        const { app_name, services, amount } = curr;
        pre['amount'] = pre['amount']
          ? [...pre['amount'], [app_name, amount]]
          : [[app_name, amount]];
        Object.keys(services).forEach((key) => {
          if (!services[key]) {
            return;
          }
          pre[key] = pre[key]
            ? [...pre[key], [app_name, services[key]]]
            : [[app_name, services[key]]];
        });
        return pre;
      },
      {} as {
        [key in BillService | 'amount']?: [string, number][];
      }
    );
  }, [appDetail]);

  return (
    <div className={styles.container}>
      <Table striped sortable singleLine loading={loading} fillParent>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell collapsing content={t('label.appName')} rowSpan={2} />
            <Table.HeaderCell
              collapsing
              textAlign="right"
              sorted={sortRule.key === 'amount' ? sortRule.rule : undefined}
              onClick={() => sort('amount')}
              content={t('billing.monthTotal')}
            />
            {displayServices.map((service) => {
              return (
                <Table.HeaderCell
                  key={service}
                  textAlign="right"
                  content={t(`billing.type.${service}`)}
                  sorted={sortRule.key === service ? sortRule.rule : undefined}
                  onClick={() => sort(service)}
                />
              );
            })}
          </Table.Row>

          <Table.Row>
            <Table.HeaderCell
              singleLine
              textAlign="right"
              content={
                <Popup
                  hoverable
                  inverted={false}
                  position="bottom left"
                  content={
                    <DisplayAppTableChart
                      data={groupServiceData.amount!}
                      title={t('billing.monthTotal')}
                    />
                  }
                  trigger={
                    <span className={styles.piePopup}>
                      <Amount value={_.sumBy(groupServiceData.amount!, ([, amount]) => amount)} />
                      <span className={styles.pieChart}>
                        <Icon name="chart pie" />
                      </span>
                    </span>
                  }
                />
              }
            />
            {displayServices.map((service) => {
              const sumAmount = _.sumBy(groupServiceData[service] || [], ([, amount]) => amount);
              return (
                <Table.HeaderCell
                  singleLine
                  key={service}
                  textAlign="right"
                  content={
                    <Popup
                      hoverable
                      inverted={false}
                      disabled={!groupServiceData[service]}
                      position="bottom left"
                      content={
                        <DisplayAppTableChart
                          data={groupServiceData[service]!}
                          title={t(`billing.type.${service}`)}
                        />
                      }
                      trigger={
                        <span className={styles.piePopup}>
                          <Amount value={sumAmount} />
                          <span className={styles.pieChart}>
                            <Icon name="chart pie" />
                          </span>
                        </span>
                      }
                    />
                  }
                />
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedData.map((item) => (
            <DisplayTableRow
              key={item.app_id}
              displayServices={displayServices}
              appName={item.app_name}
              services={item.services}
              amount={item.amount}
            />
          ))}
          {sortedData.length === 0 && <NoDataRow />}
        </Table.Body>
      </Table>
    </div>
  );
};
