import React from 'react';
import { useTranslation } from 'react-i18next';
import { currency } from 'config';
import { formatMoney, formatTime, TimeFormat } from 'utils';
import Placeholder from 'components/Placeholder';
import { Button, withModal, Form, Label } from 'components/semantic';
import { Modal } from 'components/semantic';
import { Item } from 'components/Summary';
import { useAPI } from 'utils/use-api';
import styles from './index.module.scss';
import { Invoice, INVOICE_TYPE, Recharge } from './type';
import { BILLING_CENTER_PREFIX } from 'utils/request';

export default withModal<{
  id: number;
}>()(({ close, id }) => {
  const { t } = useTranslation();
  const [invoiceData, { loading }] = useAPI<
    Invoice & {
      recharges: Recharge[];
    }
  >(`${BILLING_CENTER_PREFIX}/clients/self/invoices/${id}`, undefined, [id]);

  return (
    <>
      <Modal.Header content={t('billing.invoice.detail')} />
      <Modal.Content scrolling>
        <Form loading={loading}>
          <Form.Field>
            <label>{t('billing.invoice.type')}</label>
            <Label
              content={
                invoiceData?.invoice_type === INVOICE_TYPE.normal
                  ? t('billing.invoice.type.normal')
                  : t('billing.invoice.type.VAT')
              }
            />
          </Form.Field>
          <Form.Field>
            <label>{t('billing.invoice.title')}</label>
            <p>{invoiceData?.invoice_title}</p>
          </Form.Field>
          <Form.Field>
            <label>{t('billing.invoice.item')}</label>
            <Label content={invoiceData?.invoice_item} />
          </Form.Field>

          <Form.Field>
            <label>{t('billing.invoice.number')}</label>
            <p>{invoiceData?.taxpayer_no}</p>
          </Form.Field>
          {invoiceData?.invoice_type === INVOICE_TYPE.VAT && (
            <>
              <Form.Field>
                <label>{t('billing.invoice.VAT.name')}</label>
                <p>{invoiceData.taxpayer_name || t('label.none')}</p>
              </Form.Field>
              <Form.Field>
                <label>{t('billing.invoice.VAT.phone')}</label>
                <p>{invoiceData.taxpayer_phone || t('label.none')}</p>
              </Form.Field>
              <Form.Field>
                <label>{t('billing.invoice.VAT.address')}</label>
                <p>{invoiceData.taxpayer_address || t('label.none')}</p>
              </Form.Field>
              <Form.Field>
                <label>{t('billing.invoice.VAT.bankName')}</label>
                <p>{invoiceData.taxpayer_bank || t('label.none')}</p>
              </Form.Field>
              <Form.Field>
                <label>{t('billing.invoice.VAT.bankAccount')}</label>
                <p>{invoiceData.taxpayer_bank_account || t('label.none')}</p>
              </Form.Field>
            </>
          )}
          <Form.Field>
            <label>{t('billing.invoice.name')}</label>
            <p>{invoiceData?.reciever_name}</p>
          </Form.Field>
          <Form.Field>
            <label>{t('billing.invoice.phone')}</label>
            <p>{invoiceData?.reciever_phone}</p>
          </Form.Field>
          <Form.Field>
            <label>{t('billing.invoice.address')}</label>
            <p>{invoiceData?.reciever_address}</p>
          </Form.Field>
          <Form.Field>
            <label>{t('label.notes')}</label>
            <p>{invoiceData?.remark}</p>
          </Form.Field>
          <Form.Field>
            <label>{t('billing.invoice.recharges')}</label>
            <ul className={styles.displayList}>
              {loading && <Placeholder line={4} />}
              {!loading &&
                invoiceData?.recharges.map((recharge) => {
                  return (
                    <li key={recharge.id}>
                      <b>{formatMoney(recharge.amount)}</b> {currency}{' '}
                      <span className={styles.remark}>{recharge.remark}</span>
                      {recharge.payer_name && <Label content={recharge.payer_name} color="blue" />}
                      <span className={`${styles.created} text-muted`}>
                        {formatTime(recharge.created.iso, TimeFormat.EDITABLE_DATETIME_WITH_SECOND)}
                      </span>
                    </li>
                  );
                })}
            </ul>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <Item
          className={styles.item}
          name={t('billing.invoice.total')}
          value={formatMoney(invoiceData?.amount || 0)}
          suffix={currency}
        />
        <Button onClick={close} content={t('action.close')} />
      </Modal.Actions>
    </>
  );
});
