import { useState, useMemo, useEffect, useRef, useCallback } from 'react';

export default function useSelected<T>(data: T[]) {
  // 使用 Ref 避免每次 data 修改重新生成 action
  const dataRef = useRef<T[]>([]);
  const [selected, setSelected] = useState<boolean[]>([]);
  useEffect(() => {
    dataRef.current = data;
    setSelected(new Array(data.length).fill(false));
  }, [data]);

  const toggleSelected = useCallback(
    (index: number) =>
      setSelected((preSelected) =>
        preSelected.map((v, i) => {
          return i === index ? !v : v;
        })
      ),
    []
  );

  const actions = useMemo(() => {
    const allSelected = selected.length > 0 && selected.every((v) => v);
    const setAllSelected = (value: boolean) =>
      setSelected((preSelected) => new Array(preSelected.length).fill(value));
    return {
      allSelected,
      setAllSelected,
      toggleAllSelected: () => setAllSelected(!allSelected),
      getSelectedItems: () => dataRef.current.filter((value, index) => selected[index]),
      getUnselectedItems: () => dataRef.current.filter((value, index) => !selected[index]),
    };
  }, [selected]);

  return [selected, toggleSelected, actions] as const;
}
