import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import {
  IDVerifyEnabled,
  ICPBeianEnabled,
  InvoiceAvailable,
  DedicatedIPEnabled,
} from 'config/feature-flags';
import AVFRPage from 'components/AVFRPage';
import {
  Header,
  RegionSelect,
  AppSelect,
  LocaleSelect,
  Help,
  User,
  MenuItem,
} from 'components/Header';
import { HomeLink } from 'components/HomeLink';
import * as layoutStyles from 'components/Layout';
import { useMenu, styles as menuStyles } from 'components/Menu';
import { Icon } from 'components/semantic';
import useSidebar from 'components/use-sidebar';
import { useUser } from 'App/User';
import { withIDVerify, useIDVerify } from 'App/utils';
import AccessTokens from './AccessTokens';
import Beian from './Beian';
import Billing from './Billing';
import DeleteAccount from './DeleteAccount';
import Email from './Email';
import BillingInvoice from './Invoice';
import IP from './IP';
import { PayCallback } from './PayCallcak';
import Security from './Security';
import Verify from './Verify';
import Profile from './Profile';
import Teams from './Teams';

const Recharge = () => {
  const { t } = useTranslation();
  return <AVFRPage title={t('finance')} path={`/bill.html#/bill/charge/account`} />;
};

const Payments = () => {
  const { t } = useTranslation();
  return <AVFRPage title={t('finance')} path={`/bill.html#/bill/record`} />;
};
const Finance = () => {
  const { t } = useTranslation();
  return <AVFRPage title={t('finance')} path={`/bill.html#/bill/general`} />;
};
const Alert = () => {
  const { t } = useTranslation();
  return <AVFRPage title={t('account.settings.alert')} path={`/settings.html#/setting/alert`} />;
};
const BillingDetails = () => {
  return <AVFRPage title="Consumption details" path={`/bill.html#/bill/cost`} />;
};

const [
  IDVerifiedIP,
  IDVerifiedEmail,
  IDVerifiedProfile,
  IDVerifiedAccessTokens,
  IDVerifiedSecurity,
  IDVerifiedTeams,
] = [IP, Email, Profile, AccessTokens, Security, Teams].map((component) => withIDVerify(component));

export default ({ match }: RouteComponentProps) => {
  const { render } = useMenu();
  const { t } = useTranslation();
  const verified = useIDVerify();
  const menu = (
    <div className={menuStyles.menu}>
      <HomeLink />
      {render(
        {
          exact: true,
          sub: [
            {
              exact: true,
              i18nKey: 'account.settings',
              items: [
                {
                  path: 'profile',
                  i18nKey: 'account.settings.profile',
                  disabled: !verified,
                },
                {
                  path: 'email',
                  i18nKey: 'account.settings.email',
                  disabled: !verified,
                },
                {
                  path: 'tokens',
                  i18nKey: 'account.accessTokens',
                  disabled: !verified,
                },
                {
                  path: 'security',
                  i18nKey: 'account.settings.security',
                  disabled: !verified,
                },
                IDVerifyEnabled
                  ? {
                      path: 'verify',
                      i18nKey: 'account.settings.verify',
                    }
                  : false,
                ICPBeianEnabled
                  ? {
                      path: 'beian',
                      i18nKey: 'account.settings.beian',
                      disabled: !verified,
                    }
                  : false,
                DedicatedIPEnabled
                  ? {
                      path: 'ips',
                      i18nKey: 'account.settings.IPs',
                      disabled: !verified,
                    }
                  : false,
                {
                  path: 'teams',
                  i18nKey: 'account.settings.teams',
                  disabled: !verified,
                },
                {
                  path: 'delete-account',
                  i18nKey: 'account.unregister',
                },
              ],
            },
            {
              exact: true,
              i18nKey: 'finance',
              items: [
                {
                  path: 'finance',
                  i18nKey: 'label.overview',
                },
                {
                  path: 'alert',
                  i18nKey: 'account.settings.alert',
                },
                {
                  path: 'billing',
                  i18nKey: 'billing',
                },
                InvoiceAvailable
                  ? {
                      path: 'invoice',
                      i18nKey: 'billing.invoice',
                    }
                  : false,
              ],
            },
          ],
        },
        { baseUrl: match.url }
      )}
    </div>
  );

  const [withSidebar, { setVisible, alwaysVisible }] = useSidebar();
  const [user] = useUser();

  return withSidebar(menu)(
    <div className={layoutStyles.main}>
      <Helmet
        titleTemplate={`%s · ${t('label.account')} · LeanCloud`}
        defaultTitle={`${t('label.account')} · LeanCloud`}
      />
      <Header>
        {!alwaysVisible && (
          <MenuItem onClick={() => setVisible((v) => !v)}>
            <Icon name="bars" fitted />
          </MenuItem>
        )}
        <RegionSelect />
        <AppSelect />
        <span className="space" />
        <LocaleSelect />
        <Help />
        {user && <User user={user} />}
      </Header>
      <div className={layoutStyles.content}>
        <Switch>
          <Route path={`${match.path}/profile`} component={IDVerifiedProfile} />
          <Route path={`${match.path}/email`} component={IDVerifiedEmail} />
          <Route path={`${match.path}/tokens`} component={IDVerifiedAccessTokens} />
          <Route path={`${match.path}/security`} component={IDVerifiedSecurity} />
          {IDVerifyEnabled && <Route path={`${match.path}/verify`} component={Verify} />}
          {ICPBeianEnabled && <Route path={`${match.path}/beian`} component={Beian} />}
          {DedicatedIPEnabled && <Route path={`${match.path}/ips`} component={IDVerifiedIP} />}
          <Route path={`${match.path}/teams`} component={IDVerifiedTeams} />
          <Route path={`${match.path}/delete-account`} component={DeleteAccount} />
          <Route path={`${match.path}/finance/recharge`} exact component={Recharge} />
          <Route path={`${match.path}/finance/payments`} exact component={Payments} />
          <Route path={`${match.path}/finance`} component={Finance} />
          <Route path={`${match.path}/alert`} component={Alert} />
          <Route path={`${match.path}/billing`} exact component={Billing} />
          <Route path={`${match.path}/billing/details`} component={BillingDetails} />

          {InvoiceAvailable && <Route path={`${match.path}/invoice`} component={BillingInvoice} />}
          <Route exact path="/pay_callback.html" component={PayCallback} />
          <Redirect push={false} exact from={match.path} to={`${match.path}/profile`} />
        </Switch>
      </div>
    </div>
  );
};
