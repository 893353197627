import React, { useMemo, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { currency } from 'config';
import produce from 'immer';
import { formatMoney, formatTime, TimeFormat } from 'utils';
import ExternalLink from 'components/ExternalLink';
import { NoDataRow } from 'components/NoData';
import Page, { PageContainer } from 'components/Page';
import Pagination, { usePagination } from 'components/Pagination';
import { Message, Button, Table, Label, LabelProps } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { BILLING_CENTER_PREFIX } from 'utils/request';
import { APIError, useAPI, useDefault, useLocalState, useSmoothReload } from 'utils/use-api';
import { useUserDetail } from '../UserDetails';
import ApplyModal from './ApplyModal';
import Details from './Details';
import { Invoice, INVOICE_STATUS } from './type';

const InvoiceRow: React.FunctionComponent<{
  invoice: Invoice;
  onRevoked: (id: number) => void;
}> = ({ invoice, onRevoked }) => {
  const { t } = useTranslation();
  const cancelInvoice = useCallback(
    () =>
      request(`${BILLING_CENTER_PREFIX}/clients/self/invoices/${invoice.id}/cancel`, {
        method: 'POST',
      })
        .then(() => {
          onRevoked(invoice.id);
          toast.success(t('action.revoke.successfully'));
        })
        .catch(() => {
          toast.success(t('action.revoke.failed'));
        }),
    [invoice.id, onRevoked, t]
  );

  const labelProps: LabelProps = useMemo(() => {
    switch (invoice.status) {
      case INVOICE_STATUS.pending:
        return {
          content: t('billing.invoice.status.pending'),
          color: 'orange',
        };
      case INVOICE_STATUS.verifySuccess:
        return {
          content: t('billing.invoice.status.verifySuccess'),
          color: 'blue',
        };
      case INVOICE_STATUS.success:
        return {
          content: t('billing.invoice.status.success'),
          color: 'green',
        };
      case INVOICE_STATUS.cancelled:
        return {
          content: t('billing.invoice.status.cancelled'),
        };
    }
  }, [invoice.status, t]);

  return (
    <Table.Row>
      <Table.Cell>
        <Details
          id={invoice.id}
          modalProps={{
            trigger: <span className="link">{invoice.id}</span>,
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        {formatMoney(invoice.amount)} {currency}
      </Table.Cell>
      <Table.Cell>
        <Label basic {...labelProps} />{' '}
        {invoice.status === INVOICE_STATUS.pending && (
          <Button content={t('action.revoke')} size="mini" basic negative onClick={cancelInvoice} />
        )}
      </Table.Cell>
      <Table.Cell>{formatTime(invoice.created.iso, TimeFormat.DISPLAY_DATETIME)}</Table.Cell>
      <Table.Cell>
        {invoice.delivered && formatTime(invoice.delivered.iso, TimeFormat.DISPLAY_DATETIME)}
      </Table.Cell>
      <Table.Cell>
        {invoice.status === INVOICE_STATUS.success && (
          <span className="text-success">{invoice.express_info}</span>
        )}
        {invoice.status === INVOICE_STATUS.verifySuccess && (
          <span className="text-success">{t('billing.invoice.shipping')}</span>
        )}
      </Table.Cell>
    </Table.Row>
  );
};

const defaultResult: {
  total: number;
  results: Invoice[];
} = {
  total: 0,
  results: [],
};
export default () => {
  const { t } = useTranslation();
  const { limit, skip } = usePagination();
  const [userDetail] = useUserDetail();
  const [{ total, results: invoices }, { reload, error, loading, setData: setInvoiceData }] =
    useDefault(
      useLocalState(
        useSmoothReload(
          useAPI<{
            total: number;
            results: Invoice[];
          }>(
            `${BILLING_CENTER_PREFIX}/clients/self/invoices`,
            {
              query: {
                limit,
                skip,
              },
            },
            [skip, limit]
          )
        )
      ),
      defaultResult
    );

  const onRevoked = useCallback(
    (id: number) => {
      setInvoiceData((pre) =>
        produce(pre, (draft) => {
          const invoice = draft?.results.find((item) => item.id === id);
          if (invoice) {
            invoice.status = INVOICE_STATUS.cancelled;
          }
        })
      );
    },
    [setInvoiceData]
  );

  const presetData: Partial<Invoice> | undefined = useMemo(() => {
    if (invoices[0]) {
      return invoices[0];
    }
    if (userDetail) {
      if (userDetail.address && userDetail.phone && userDetail.invoiceTitle) {
        return {
          reciever_address: userDetail.address,
          reciever_phone: userDetail.phone,
          invoice_title: userDetail.invoiceTitle,
          invoice_type: 1,
        };
      }
      return;
    }
    return;
  }, [invoices, userDetail]);

  return (
    <Page title={t('billing.invoice')}>
      <PageContainer>
        <ApplyModal
          onSuccess={reload}
          initData={presetData}
          modalProps={{
            trigger: <Button disabled={loading} content={t('billing.invoice.applyInvoice')} />,
          }}
        />
        {error && (
          <APIError error={error} message={t('billing.invoice.fetchError')} retry={reload} />
        )}
        {!loading && !presetData && (
          <Message warning content={t('billing.invoice.presetInfoHint')} />
        )}
        <Message
          info
          content={
            <Trans
              i18nKey="billing.invoice.hint"
              components={{
                Link: (
                  // eslint-disable-next-line i18n/no-chinese-character
                  <ExternalLink href="https://docs.leancloud.cn/sdk/start/dashboard/#账单和发票" />
                ),
              }}
            />
          }
        />
        <Table loading={loading} striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell content={'ID'} />
              <Table.HeaderCell content={t('billing.invoice.applyMoney')} textAlign="right" />
              <Table.HeaderCell content={t('label.status')} />
              <Table.HeaderCell content={t('billing.invoice.applyDate')} />
              <Table.HeaderCell content={t('billing.invoice.createAt')} />
              <Table.HeaderCell content={t('billing.invoice.logistics')} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {invoices.map((invoice) => {
              return <InvoiceRow onRevoked={onRevoked} invoice={invoice} key={invoice.id} />;
            })}
            {total === 0 && <NoDataRow />}
          </Table.Body>
        </Table>
        {total > 0 && <Pagination count={total} />}
      </PageContainer>
    </Page>
  );
};
