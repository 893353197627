import React, { useCallback, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { formatTime, TimeFormat } from 'utils';
import Confirm from 'components/Confirm';
import { CopiableCode } from 'components/CopiableText';
import { NoDataRow } from 'components/NoData';
import Page, { PageContainer } from 'components/Page';
import {
  Button,
  Table,
  Modal,
  LinkButton,
  Message,
  Form,
  Input,
  withModal,
  List,
} from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import { withTracker } from 'utils/tracker';
import { useAPI, APIError, useSmoothReload } from 'utils/use-api';
import styles from './index.module.scss';

export interface Token {
  id: number;
  clientId: number;
  name: string;
  token: string;
  created: string;
}

const FORM_ID = 'Token';

const TokenModal = withModal<{
  onGenerated?: (token: Token) => void;
  token?: Token;
  reload?: () => void;
}>()(({ token, onGenerated, close, reload }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const [loading, setLoading] = useState(false);

  const createToken = useCallback(
    ({ name }: Pick<Token, 'name'>) => {
      setLoading(true);
      request<Token>(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/authTokens`, {
        method: 'POST',
        body: { name },
      })
        .then((token) => {
          onGenerated?.(token);
          close();
        })
        .catch((err) => toast.error(t('action.create.failed'), err))
        .finally(() => setLoading(false));
    },
    [close, onGenerated, t]
  );

  const updateToken = useCallback(
    ({ name }: Pick<Token, 'name'>) => {
      if (!token) {
        return;
      }
      setLoading(true);
      request<Token>(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/authTokens/${token.id}`,
        {
          method: 'PUT',
          body: { name },
        }
      )
        .then((token) => {
          reload && reload();
          close();
        })
        .catch((err) => toast.error(t('action.create.failed'), err))
        .finally(() => setLoading(false));
    },
    [token, reload, close, t]
  );

  return (
    <>
      <Modal.Header>
        {token ? (
          <Trans
            i18nKey="account.accessTokens.update.header"
            values={{ name: token.name }}
            components={{ code: <code /> }}
          />
        ) : (
          t('account.accessTokens.generate.header')
        )}
      </Modal.Header>
      <Modal.Content>
        <Form
          id={FORM_ID}
          onSubmit={() => (token ? updateToken({ name: comment }) : createToken({ name: comment }))}
        >
          <Form.Field required>
            <label htmlFor="comment">{t('label.comments')}</label>
            <Input
              id="comment"
              value={comment}
              onChange={(e, { value }) => setComment(value)}
              required
              autoFocus
            />
            <p className="help-block">{t('account.accessTokens.comments.description')}</p>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton onClick={close} content={t('action.cancel')} />
        <Button
          primary
          type="submit"
          form={FORM_ID}
          loading={loading}
          disabled={loading}
          content={token ? t('action.update') : t('action.generate')}
        />
      </Modal.Actions>
    </>
  );
});

export const AccessTokenManager = () => {
  const { t } = useTranslation();
  const [newlyCreatedToken, setNewlyCreatedToken] = useState<Token>();
  const [open, setOpen] = useState(false);
  const [currentToken, setCurrentToken] = useState<Token>();
  const [tokens, { error, loading, reload }] = useSmoothReload(
    useAPI<Token[]>(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/authTokens`)
  );

  const generatedTokenHandler = (token: Token) => {
    setNewlyCreatedToken(token);
    reload();
  };

  const deleteToken = useCallback(
    (id: number) =>
      request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/authTokens/${id}`, {
        method: 'DELETE',
      })
        .then(reload)
        .catch((err) => toast.error(t('action.delete.failed'), err)),
    [reload, t]
  );

  return (
    <>
      {newlyCreatedToken && (
        <Message success>
          <Message.Header>{t('account.accessTokens.generate.success')}</Message.Header>
          <Message.Content>
            <p>
              <CopiableCode code={newlyCreatedToken.token} />
            </p>
            <p>{t('account.accessTokens.copyNow')}</p>
          </Message.Content>
        </Message>
      )}
      <div>
        <TokenModal
          token={currentToken}
          onGenerated={generatedTokenHandler}
          reload={reload}
          modalProps={{
            open,
            onClose: () => setOpen(false),
          }}
        />
        <Button
          onClick={() => {
            setCurrentToken(undefined);
            setOpen(true);
          }}
        >
          {t('account.accessTokens.generate')}
        </Button>
        <Button onClick={reload} icon="refresh" />
      </div>
      <APIError message={t('account.accessTokens.fetchFailed')} error={error} retry={reload} />
      <Table loading={loading}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('label.comments')}</Table.HeaderCell>
            <Table.HeaderCell>Token</Table.HeaderCell>
            <Table.HeaderCell>{t('label.createdAt')}</Table.HeaderCell>
            <Table.HeaderCell>{t('label.operation')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tokens?.map((token) => {
            return (
              <Table.Row key={token.id}>
                <Table.Cell>{token.name}</Table.Cell>
                <Table.Cell className={styles.token}>{token.token}</Table.Cell>
                <Table.Cell>{formatTime(token.created, TimeFormat.DISPLAY_DATETIME)}</Table.Cell>
                <Table.Cell>
                  <Confirm
                    trigger={<Button size="small" basic negative content={t('action.delete')} />}
                    header={`${t('action.delete')} access token`}
                    content={
                      <List
                        bulleted
                        items={[
                          t('account.accessTokens.deleteWarning'),
                          t('label.actionIrreversible'),
                        ]}
                      />
                    }
                    confirmButtonText={t('action.delete')}
                    onConfirm={() => deleteToken(token.id)}
                  />
                  <Button
                    size="small"
                    content={t('action.update')}
                    onClick={() => {
                      setOpen(true);
                      setCurrentToken(token);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
          {!tokens?.length && <NoDataRow />}
        </Table.Body>
      </Table>
    </>
  );
};

export default withTracker(() => {
  const { t } = useTranslation();

  return (
    <Page title={t('account.accessTokens')}>
      <PageContainer>
        <p>{t('account.accessTokens.intro')}</p>
        <AccessTokenManager />
      </PageContainer>
    </Page>
  );
});
