import React from 'react';
import AVFRPage from 'components/AVFRPage';
import * as layoutStyles from 'components/Layout';
import { AppListHeader } from 'App/AppList';

export default () => {
  return (
    <div className={layoutStyles.main}>
      <AppListHeader />
      <div className={layoutStyles.content}>
        <AVFRPage title="Notifications" path={`/info-center.html#/index`} />
      </div>
    </div>
  );
};
