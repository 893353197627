import React, { useCallback, useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { Message } from 'components/semantic';
import Splash from 'components/Splash';
import { RequestEvent, eventEmitter as requestEE } from 'utils/request';
import { useUser, resetUser } from 'App/User';

interface Props extends RouteProps {
  redirectPath?: string;
}

const AuthorizedRoute: React.FunctionComponent<Props> = ({
  component: Component,
  render,
  redirectPath = '/login',
  location,
  ...rest
}) => {
  const history = useHistory();
  const [user, { error }] = useUser();

  const redirect = useCallback(() => {
    resetUser();
    const currentURL = location
      ? `${location.pathname}${location.search}${location.hash}`
      : undefined;
    const search = currentURL ? `?from=${encodeURIComponent(currentURL)}` : '';
    history.replace(`${redirectPath}${search}`);
  }, [history, location, redirectPath]);

  useEffect(() => {
    if (error && error.message.startsWith("User doesn't sign in")) {
      redirect();
    }
  }, [error, redirect]);

  useEffect(() => requestEE.on(RequestEvent.Unauthornized, redirect), [redirect]);

  if (user) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (Component) {
            return <Component {...props} />;
          }
          if (render) {
            return render(props);
          }
          throw Error('AuthorizedRoute requires render or component');
        }}
      />
    );
  }
  if (error) {
    return (
      <div className="splash">
        <Message error icon="dizzy outline" content={error.message} />
      </div>
    );
  }
  return <Splash />;
};

export default AuthorizedRoute;
