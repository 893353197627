import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { createResourceHook } from '@leancloud/use-resource';
import { getRegionalConfig } from 'config';
import * as LC from 'open-leancloud-storage';
import { formatTime, TimeFormat } from 'utils';
import ExternalLink from 'components/ExternalLink';
import Panel from 'components/Panel';
import { Item } from 'components/semantic';
import { APIError } from 'utils/use-api';
import styles from './index.module.scss';

type module = 'storage' | 'engine' | 'push' | 'IM' | 'sms' | 'play';
interface News {
  objectId: string;
  title: string; //	新闻的标题	是
  content: string; //	新闻的内容	是
  link?: string; //	新闻链接 （指向文档 blog 等）	否
  linkContent?: string; //	链接文案	否
  module?: module;
  expiredAt: Date; // Date 过期日期
  createdAt: Date; //	Date 创建日期	是
  cn_n1: boolean; //	节点	是
  cn_e1: boolean; // 节点	是
  us_w1: boolean; //	节点	是
}

const newsApp = LC.init({
  appId: 'OMMmeGjCJj0ffikS8X9ins2y-gzGzoHsz',
  appKey: 'HzMD9NSVkatp3y7F6gVn3iEq',
  serverURL: 'https://ommmegjc.lc-cn-n1-shared.com',
});
const regional = getRegionalConfig({
  'cn-e1': {
    cn_e1: true,
  },
  'cn-n1': {
    cn_n1: true,
  },
  'cn-rnd': {
    cn_n1: true,
  },
  'cn-tds1': {
    cn_tds1: true,
  },
  'us-w1': {
    us_w1: true,
  },
  'ap-sg': {
    ap_sg: true,
  },
});

const getNews = (module?: module) => {
  const db = newsApp.database();
  const query = db
    .class('News')
    .where({
      ...regional,
      module,
      expiredAt: db.cmd.gt(new Date()),
    })
    .orderBy('createdAt', 'desc')
    .limit(3);
  const abortController = new AbortController();
  return {
    abort: () => abortController.abort(),
    promise: query
      .find({ abortSignal: abortController.signal })
      .then((objects) => objects.map((o) => o.data as News)),
  };
};
const useNews = createResourceHook(getNews);

const NewsItem = memo<{ newInfo: News }>(({ newInfo }) => {
  const { t } = useTranslation();
  const { title, createdAt, content, link, linkContent } = newInfo;
  return (
    <Item className={styles.newsItem}>
      <Item.Content>
        <Item.Header content={title} className={styles.title} />
        <Item.Meta content={formatTime(createdAt, TimeFormat.DISPLAY)} />
        <Item.Description content={content} />
        {link && (
          <Item.Content className={styles.link}>
            <ExternalLink href={link}>{linkContent || t('action.viewDetail')}</ExternalLink>
          </Item.Content>
        )}
      </Item.Content>
    </Item>
  );
});

export default memo(() => {
  const { t } = useTranslation();
  const [news, { error, reload }] = useNews([]);
  if (error) {
    return <APIError error={error} message={t('fetchError.data')} retry={reload} />;
  }
  if (!news || news.length === 0) {
    return null;
  }
  return (
    <Panel title={t('news')}>
      <Item.Group>
        {news.map((newInfo) => {
          return <NewsItem newInfo={newInfo} key={newInfo.objectId} />;
        })}
      </Item.Group>
    </Panel>
  );
});
