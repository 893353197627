import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page, { PageContainer } from 'components/Page';
import Placeholder from 'components/Placeholder';
import Panel from 'components/Panel';
import _ from 'lodash';
import { Button, Modal, LinkButton, Form, Input, Icon, Message } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import { useUser } from 'App/User';
import { useUserDetail, UserDetail } from 'App/Account/UserDetails';
import { Avatar } from 'components/Gravatar';
import Phone from './Phone';
import styles from './index.module.scss';

const UserInfoModal: FC<{
  open: boolean;
  userDetail: UserDetail;
  onSuccess: (props: UserDetail) => void;
  onClose: () => void;
}> = ({ open = false, userDetail, onSuccess, onClose }) => {
  const { t } = useTranslation();
  const [detail, setDetail] = useState(userDetail);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      setDetail(userDetail);
    }
  }, [open, userDetail]);

  const updateDetail = (props: Partial<UserDetail>) => {
    setDetail({ ...detail, ...props });
  };

  const onSubmit = async () => {
    if (!detail) {
      return;
    }
    setLoading(true);
    const body = _.mapValues(detail, function (v) {
      return _.isNull(v) ? undefined : v;
    });
    try {
      const res = await request<UserDetail>(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/detail`,
        {
          method: 'POST',
          body,
        }
      );
      onSuccess(res);
      toast.success(t('action.update.successfully'));
    } catch (error) {
      toast.error(t('action.update.failed'), error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>{t('profile.detail.edit')}</Modal.Header>
      <Modal.Content>
        <Form id="userInfo" onSubmit={onSubmit}>
          <Form.Field required>
            <label>{t('profile.clientType')}</label>
            <Form.Group inline>
              <Form.Radio
                label={t('profile.clientType.user')}
                value={1}
                checked={detail?.clientType === 0}
                onChange={() => updateDetail({ clientType: 0 })}
              />
              <Form.Radio
                label={t('profile.clientType.company')}
                value={0}
                checked={detail?.clientType === 1}
                onChange={() => updateDetail({ clientType: 1 })}
              />
            </Form.Group>
            <p className="help-block">{t('profile.clientType.hint')}</p>
          </Form.Field>
          <div className={styles.formContent}>
            <Form.Input
              required
              label={t('profile.clientType.name')}
              value={detail?.clientName}
              onChange={(e, data) => {
                updateDetail({ clientName: data.value });
              }}
            />
            <Form.Input
              label={t('profile.address')}
              value={detail?.address}
              onChange={(e, data) => {
                updateDetail({ address: data.value });
              }}
            />
            <Form.Field required>
              <label htmlFor="backupPhone">{t('profile.backupPhone')}</label>
              <Input
                name="backupPhone"
                id="backupPhone"
                required
                value={detail?.phone}
                onChange={(e, data) => updateDetail({ phone: data.value })}
              />
              <p className="help-block">{t('profile.backupPhone.hint')}</p>
            </Form.Field>
            <Form.Field>
              <label htmlFor="invoice">{t('profile.invoice.title')}</label>
              <Input
                name="invoice"
                id="invoice"
                value={detail?.invoiceTitle || ''}
                onChange={(e, data) => updateDetail({ invoiceTitle: data.value })}
              />
              <p className="help-block">{t('profile.invoice.title.hint')}</p>
            </Form.Field>
            <Form.Input
              label={t('profile.compony.name')}
              value={detail?.company || ''}
              onChange={(e, data) => {
                updateDetail({ company: data.value });
              }}
            />
            <Form.Input
              label={t('profile.website')}
              value={detail?.companySite || ''}
              onChange={(e, data) => updateDetail({ companySite: data.value })}
            />
            <Form.Input
              label={'QQ'}
              value={detail?.oicq || ''}
              onChange={(e, data) => updateDetail({ oicq: data.value })}
            />
          </div>
        </Form>
        <Message info>
          <Message.Header>{t('profile.reason')}</Message.Header>
          <Message.List>
            <Message.Item>{t('profile.reason.1')}</Message.Item>
            <Message.Item>{t('profile.reason.2')}</Message.Item>
            <Message.Item>{t('profile.reason.3')}</Message.Item>
            <Message.Item>{t('profile.reason.4')}</Message.Item>
          </Message.List>
        </Message>
      </Modal.Content>
      <Modal.Actions>
        <LinkButton content={t('action.cancel')} onClick={onClose} />
        <Button
          primary
          form="userInfo"
          type="submit"
          loading={loading}
          content={t('action.update')}
        />
      </Modal.Actions>
    </Modal>
  );
};

const UserInfo: FC = () => {
  const { t } = useTranslation();
  const [user, { loading }] = useUser();
  const [userDetail, { loading: detailLoading, setData }] = useUserDetail();
  const [modalOpen, setModalOpen] = useState(false);
  if (loading || detailLoading || !userDetail || !user) {
    return <Placeholder />;
  }

  const { address, clientType, company, companySite, oicq, invoiceTitle } = userDetail;

  return (
    <div className={styles.info}>
      <UserInfoModal
        open={modalOpen}
        userDetail={userDetail}
        onClose={() => setModalOpen(false)}
        onSuccess={(props) => {
          setModalOpen(false);
          setData({ ...userDetail, ...props });
        }}
      />
      <div className={styles.basic}>
        <Avatar email={user.email} size={200} />
        <p className={styles.username}>{user.username}</p>
      </div>
      <div className={styles.userPanel}>
        <h4>
          {t('profile.detail')}{' '}
          <Button size="mini" onClick={() => setModalOpen(true)} content={t('action.edit')} />
        </h4>
        <p>
          <Icon name={clientType === 0 ? 'user alternate' : 'group'} />
          {t(clientType === 0 ? 'profile.account.user' : 'profile.account.company')}
        </p>
        <p>
          <Icon name="phone alternate" size="small" />
          {user.phone} <Phone />
        </p>
        {company && (
          <p>
            <Icon name="building" />
            {company}
          </p>
        )}
        {address && (
          <p>
            <Icon name="location" />
            {address}
          </p>
        )}
        {companySite && (
          <p>
            <Icon name="globe" />
            {companySite}
          </p>
        )}
        {invoiceTitle && (
          <p>
            <Icon name="receipt" />
            {invoiceTitle}
          </p>
        )}
        {oicq && (
          <p>
            <Icon name="qq" />
            {oicq}
          </p>
        )}
      </div>
    </div>
  );
};

export default function Profile() {
  const { t } = useTranslation();
  return (
    <Page title={t('account.settings.profile')}>
      <PageContainer>
        <Panel>
          <UserInfo />
        </Panel>
      </PageContainer>
    </Page>
  );
}
