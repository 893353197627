import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { formatTime, TimeFormat } from 'utils';
import { toast } from 'components/Toast';
import request, { BILLING_CENTER_PREFIX, API_VERSION } from 'utils/request';
import { useAPI, useTransform } from 'utils/use-api';
import { PricingServiceType, PricingServiceItem } from 'App/Pricing';

type BillItem<T extends PricingServiceType> = {
  amount: number;
  currency: 'CNY' | 'USD';
  price: number;
  service: T;
  item: PricingServiceItem[T];
  bill_date: string; // "20201031"

  // TODO 下面类型 未细分 具体使用某个属性时 在具体细分
  app_id: string;
  app_name: string;
  client_id: number;
  created: { __type: 'Date'; iso: string };
  free: number; // 0
  id: number;
  item_no: null;
  quantity: number;
  quota: number;
  sha_id: string;
  status: number;
  usage: number;
};

export const useServiceBill = (form: Date, to?: Date) => {
  return useTransform(
    useAPI<{ results: BillItem<PricingServiceType>[] }>(
      `${BILLING_CENTER_PREFIX}/clients/self/service-bills`,
      {
        query: {
          from: formatTime(form, TimeFormat.QUERY),
          limit: 10000,
          to: formatTime(to || new Date(), TimeFormat.QUERY),
        },
      },
      [form, to]
    ),
    useCallback((data) => (data ? data.results : []), [])
  );
};

interface Threshold {
  compare: string;
  indb: boolean;
  interval: number;
  new: boolean;
  threshold: number;
}
type ThresholdType = 'client_balance';
export const useAccountThreshold = (type: ThresholdType) => {
  const { t } = useTranslation();
  const [updating, setUpdating] = useState(false);
  const [threshold, extra] = useTransform(
    useAPI<
      {
        [key in ThresholdType]: Threshold;
      }
    >(`/${API_VERSION}/clients/self/thresholds`),
    useCallback(
      (data) => {
        if (!data || !data[type]) {
          return;
        }
        return data[type];
      },
      [type]
    )
  );

  const update = useCallback(
    async (value: number) => {
      try {
        setUpdating(true);
        await request(`/${API_VERSION}/clients/self/thresholds`, {
          method: 'POST',
          body: {
            [type]: {
              ...threshold,
              threshold: value,
            },
          },
        });
        setUpdating(false);
        toast.success(t('action.set.successfully'));
      } catch (error) {
        setUpdating(false);
        toast.error(t('action.set.failed'), error);
      }
    },
    [threshold, t, type]
  );
  return [threshold?.threshold, { ...extra, update, updating }] as const;
};

enum Category {
  finance = 1,
  cooperation = 2,
  site = 3,
  tool = 4,
  warning = 5,
}
export interface Notification {
  category: Category;
  created: { __type: 'Date'; iso: string };
  id: number;
  idc_id?: number;
  message: string;
  notify_type: number;
  status: number;
  tip_type: string;
  title?: null | string;
  expire_at?: number; // -1
}
// const sortNotification = (notifications?: Notification[]) => {
//   if (!notifications) {
//     return;
//   }
//   return _(notifications)
//     .orderBy('id', 'desc')
//     .valueOf();
// };

// query: {
//           limit,
//           skip: 0
//         },
export const useNotifications = (limit = 1000) => {
  return useAPI<Notification[]>(`/${API_VERSION}/sitenotifications`, {
    query: {
      limit,
      skip: 1, // 忽略【功能提示】想用好云代码
    },
  });
};
