import React, { useMemo } from 'react';
import { Trans, useTranslation, TFunction } from 'react-i18next';
import { currency, currencyIconName, IPPricing } from 'config';
import moment from 'moment';
import { compose } from 'redux';
import { formatTime, Noop, TimeFormat } from 'utils';
import Confirm from 'components/Confirm';
import CopiableText from 'components/CopiableText';
import Hint from 'components/Hint';
import Page, { PageContainer } from 'components/Page';
import Panel from 'components/Panel';
import Placeholder from 'components/Placeholder';
import { isTDS1 } from 'env';
import {
  Label,
  LabelProps,
  Popup,
  Message,
  Modal,
  LinkButton,
  Card,
  Button,
  List,
  ModalProps,
} from 'components/semantic';
import { Item } from 'components/Summary';
import { toast } from 'components/Toast';
import request, { API_VERSION } from 'utils/request';
import { withTracker } from 'utils/tracker';
import { APIError, useAPI } from 'utils/use-api';
import { useApps } from 'App/Apps';
import { useAppId } from 'App/Application';
import { useUser } from 'App/User';
import styles from './index.module.scss';

type IPState = 'purchasing' | 'deploying' | 'normal' | 'failed' | 'expired';

interface IP {
  id: number;
  type: 'platform' | 'engine';
  address: string;
  charge: boolean;
  state: IPState;
  failedReason?: string;
  createdAt: string;
}

export const useIPs = () => {
  const appId = useAppId();
  const [IPs, extra] = useAPI<IP[]>(
    `/${API_VERSION}/domain-center/ips`,
    { appId },
    [appId],
    isTDS1 ? !!appId : true
  );
  const platformIPs = (IPs || []).filter((ip) => ip.type === 'platform');
  const engineIPs = (IPs || []).filter((ip) => ip.type === 'engine');

  return [{ platformIPs, engineIPs }, extra] as const;
};

export default withTracker(() => {
  const { t } = useTranslation();
  const [{ platformIPs, engineIPs }, { error, loading, reload }] = useIPs();

  return (
    <Page title={t('account.ip')}>
      <PageContainer>
        {error && <APIError error={error} message={t('account.ip.fetchError')} retry={reload} />}
        <IPList type="platform" ips={platformIPs} loading={loading} reload={reload} />
        <IPList type="engine" ips={engineIPs} loading={loading} reload={reload} />
      </PageContainer>
    </Page>
  );
});

export const ApplyIpModal = ({
  type,
  onSuccess = () => {},
  modalProps,
}: {
  type: IP['type'];
  onSuccess?: () => void;
  modalProps?: ModalProps;
}) => {
  const { t } = useTranslation();
  const [user] = useUser();
  const [apps] = useApps();
  const appId = useAppId();
  const ownedApps = useMemo(() => (apps || []).filter((app) => app.isOwner), [apps]);
  return (
    <Modal trigger={<Button>{t('account.ip.apply')}</Button>} {...modalProps}>
      {({ close }) => (
        <>
          <Modal.Header content={t('account.ip.apply.header')} />
          <Modal.Content>
            <List relaxed>
              <List.Item>
                <Item
                  name={t('label.type')}
                  suffix={<Label size="large">{t(`account.ip.${type}`)}</Label>}
                />
              </List.Item>
              <List.Item>
                <Item name={t('label.account')} suffix={user?.email} />
                <p className="help-block">
                  {t('account.ip.relatedApp')}
                  <Hint
                    hoverable
                    content={
                      ownedApps.length ? (
                        <List
                          className={styles.hintList}
                          items={ownedApps.map((app) => app.appName)}
                        />
                      ) : (
                        t('account.ip.relatedApp.none')
                      )
                    }
                  />
                </p>
              </List.Item>
              <List.Item>
                <Item
                  name={t('label.price')}
                  value={IPPricing}
                  suffix={`${currency} / ${t('label.month')}`}
                />
                <Message
                  info
                  icon={currencyIconName}
                  content={
                    <List
                      bulleted
                      items={[
                        t('account.ip.purchageHint.firstCharge'),
                        t('account.ip.purchageHint.monthlyCharge'),
                        t('account.ip.purchageHint.noRefund'),
                      ]}
                    />
                  }
                />
              </List.Item>
            </List>
          </Modal.Content>
          <Modal.Actions>
            <LinkButton content={t('action.close')} onClick={close} />
            <Button
              primary
              content={t('action.confirmBilling')}
              onClick={() =>
                request(`/${API_VERSION}/domain-center/ips`, {
                  method: 'POST',
                  body: {
                    type,
                  },
                  appId,
                }).then(compose(onSuccess, close), (error) =>
                  toast.error(t('account.ip.apply.failed'), error)
                )
              }
            />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

export const IPList = ({
  type,
  ips,
  loading,
  reload,
}: {
  type: IP['type'];
  ips: IP[];
  loading: boolean;
  reload: Noop;
}) => {
  const { t } = useTranslation();

  return (
    <Panel title={t(`account.ip.${type}`)}>
      {loading ? (
        <div>
          <Placeholder line={4} />
        </div>
      ) : ips.length === 0 ? (
        <p>{t('label.none')}</p>
      ) : (
        <Card.Group>
          {ips.map((ip) => (
            <IPCard ip={ip} key={ip.id} onDeleted={reload} />
          ))}
        </Card.Group>
      )}
      <p>
        <ApplyIpModal
          type={type}
          onSuccess={reload}
          modalProps={{
            trigger: <Button>{t('account.ip.apply')}</Button>,
          }}
        />
      </p>
    </Panel>
  );
};

export const getStateLabelProps = (state: IPState, t: TFunction<'translation'>): LabelProps => {
  switch (state) {
    case 'deploying':
      return { color: 'orange', content: t('account.ip.state.deploying') };
    case 'purchasing':
      return { color: 'orange', content: t('account.ip.state.purchasing') };
    case 'normal':
      return { color: 'green', content: t('account.ip.state.normal') };
    case 'failed':
      return { color: 'red', content: t('account.ip.state.failed') };
    case 'expired':
      return { content: t('account.ip.state.deleted') };
    default:
      return { content: state };
  }
};

const RELEASE_DATETIME = moment('2020-10-20T08:00:00.000Z');

const IPCard = ({
  ip: { id, address, type, state, createdAt, charge, failedReason },
  onDeleted,
}: {
  ip: IP;
  onDeleted?: Noop;
}) => {
  const appId = useAppId();
  const { t } = useTranslation();
  const { color: stateColor, content: stateContent } = getStateLabelProps(state, t);

  // 在独立 IP 发布之前创建的 API IP 我们统一将对应的商用版应用的 CNAME 指向了独立 IP
  // 这批独立 IP 不能由用户直接删除，需要先找我们先把 CNAME 改回去
  const unbindable = useMemo(
    () => !(type === 'platform' && moment(createdAt).isBefore(RELEASE_DATETIME)),
    [createdAt, type]
  );
  const unbindButton = unbindable ? (
    <Confirm
      header={
        <Trans i18nKey="account.ip.unbind" values={{ ip: address }}>
          Delete <code>IP</code>
        </Trans>
      }
      content={
        <List bulleted items={[t('account.ip.domainWarning'), t('label.actionIrreversible')]} />
      }
      confirmButtonText={t('action.unbind')}
      trigger={<Button negative basic size="small" content={t('action.unbind')} />}
      onConfirm={() =>
        request(`/${API_VERSION}/domain-center/ips/${id}`, {
          method: 'DELETE',
          appId,
        })
          .then(() => {
            toast.success(`${address} ${t('account.ip.unbinded')}`);
            onDeleted?.();
          })
          .catch((error) => toast.error(error.message))
      }
    />
  ) : (
    <Popup
      trigger={
        <span>
          <Button disabled negative basic size="small" content={t('action.unbind')} />
        </span>
      }
      content={t('account.ip.unbinded.contactSupport')}
    />
  );

  return (
    <Card>
      <Card.Content>
        <Card.Header>
          {charge === false && (
            <Popup
              trigger={<Label icon="gift" basic={false} corner="right" />}
              content={t('account.ip.complimentary')}
            />
          )}
          {<CopiableText text={address} content={<code>{address}</code>} />}
        </Card.Header>
        <Card.Description>
          <Label color={stateColor} content={stateContent} size="big" />
          {failedReason && <Hint content={failedReason} color={stateColor} />}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <p>
          {t('label.createAt')} {formatTime(createdAt, TimeFormat.DISPLAY_DATETIME)}
        </p>
      </Card.Content>
      <Card.Content extra>{unbindButton}</Card.Content>
    </Card>
  );
};
