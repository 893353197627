export enum INVOICE_TYPE {
  normal = 1,
  VAT = 2,
}
export enum INVOICE_STATUS {
  pending = 0, // 待审核
  success = 1, // 成功
  cancelled = 2, // 撤销
  verifySuccess = 3, // 审核通过开具发票
}

export interface Invoice {
  account_id: number;
  amount: number;
  client_id: number;
  created: { __type: 'Date'; iso: string };
  delivered?: { __type: 'Date'; iso: string };
  express_info: string;
  id: number;
  invoice_item: string;
  invoice_title: string;
  invoice_type: INVOICE_TYPE;
  recharge_list: null;
  recharges: string;
  reciever_address: string;
  reciever_name: string;
  reciever_phone: string;
  reciever_post_code: null;
  remark?: string;
  status: INVOICE_STATUS;
  taxpayer_address?: string;
  taxpayer_bank?: string;
  taxpayer_bank_account?: string;
  taxpayer_name?: string;
  taxpayer_no?: string;
  taxpayer_phone?: string;
}
export interface Recharge {
  account_id: number;
  amount: number;
  balance: number;
  client_id: number;
  created: { __type: 'Date'; iso: string };
  currency: string;
  gift: number;
  gift_balance: number;
  id: number;
  invoice_status: number;
  item_type: number;
  order_no?: string;
  payer_name?: string;
  remark?: string;
  tx_type: INVOICE_TYPE;
}

export interface Charge {
  account_id: number;
  amount: number;
  balance: number;
  client_id: number;
  created: string;
  currency: string;
  gift: number;
  gift_balance: number;
  id: number;
  invoice_status: number;
  item_type: number;
  order_no?: number;
  payer_name?: string;
  remark?: string;
  total_amount: number;
  total_balance: number;
  tx_type: number;
}
