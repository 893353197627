import { useState, useEffect, useCallback } from 'react';
import Page, { PageContainer } from 'components/Page';
import { useTranslation } from 'react-i18next';
import request, { CLIENT_CENTER_VERSION, API_VERSION } from 'utils/request';
import { useAPI, APIError } from 'utils/use-api';
import { Avatar } from 'components/Gravatar';
import _ from 'lodash';
import {
  Form,
  Input,
  Icon,
  Button,
  Modal,
  withModal,
  Search,
  FileInput,
  Placeholder,
} from 'components/semantic';
import Confirm from 'components/Confirm';
import styles from './index.module.scss';
import { toast } from 'components/Toast';

export interface Team {
  clientId: number;
  created: string;
  icon: string | null;
  iconMimeType: string | null;
  id: number;
  name: string;
}

export interface Member {
  clientId: number;
  created: string;
  emailMd5: string;
  id: number;
  name: string;
  teamId: number;
}

interface TeamDetail extends Team {
  apps: { appName: string; appId: string }[];
}

const EditModal = withModal<{ id?: number; reload?: () => void }>()(({ id, reload, close }) => {
  const [t] = useTranslation();
  const [name, setName] = useState('');
  const [searching, setSearching] = useState(false);
  const [keyword, setKeyword] = useState<string | undefined>('');
  const [options, setOptions] = useState<Member[]>();
  const [selectedOptions, setSelectedOptions] = useState<Member>();
  const [members, setMembers] = useState<Member[]>();
  const [detail, setDetail] = useState<TeamDetail>();
  const [createing, setCreateing] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);

  const getDetail = useCallback(
    async (id: number) => {
      setLoading(true);
      try {
        const data = await request<TeamDetail>(
          `/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams/${id}`
        );
        setDetail(data);
      } catch (error) {
        toast.error(t('action.query.failed'), error);
      }
      setLoading(false);
    },
    [t]
  );

  const addTeam = async () => {
    if (!name) {
      return;
    }
    setCreateing(true);
    try {
      const data = await request<TeamDetail>(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams`,
        {
          method: 'POST',
          body: { name },
        }
      );
      toast.success(t('action.add.successfully'));
      getDetail(data.id);
    } catch (error) {
      toast.error(t('action.add.failed'), error);
    }
    setCreateing(false);
  };

  const updateTeam = async ({ name, icon }: { name?: string; icon?: File }) => {
    if (!detail) {
      return;
    }
    const data = new FormData();
    name && data.append('name', name);
    icon && data.append('icon', icon);
    try {
      await request(`/${API_VERSION}/clients/self/teams/${detail.id}`, {
        method: 'POST',
        body: data,
      });
      getDetail(detail.id);
      toast.success(t('action.update.successfully'));
    } catch (error) {
      toast.error(t('action.update.failed'), error);
    }
  };

  const getMembers = useCallback(async () => {
    if (!detail?.id) {
      setMembers([]);
      return;
    }
    setMemberLoading(true);
    try {
      const { results } = await request<{ results: Member[] }>(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams/${detail.id}/members`
      );
      setMembers(results);
    } catch (error) {
      toast.error(t('action.query.failed'), error);
    }
    setMemberLoading(false);
  }, [t, detail?.id]);

  const search = _.debounce(async (name?: string) => {
    if (!name || name.trim() === '') {
      setOptions([]);
      return;
    }
    setSearching(true);
    try {
      const { results } = await request<{ results: Member[] }>(
        `/${API_VERSION}/clients/find/like`,
        {
          query: {
            name,
            type: 0,
          },
        }
      );
      const res = results.slice(0, 10).map((item) => ({ ...item, title: item.name }));
      setOptions(res);
    } catch (error) {
      toast.error(t('action.query.failed'), error);
    } finally {
      setSearching(false);
    }
  }, 100);

  const addMember = async (member: Member) => {
    if (!detail) {
      return;
    }
    setMemberLoading(true);
    try {
      await request(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams/${detail.id}/members`,
        {
          method: 'POST',
          body: {
            memberId: member.id,
            teamid: detail.id,
          },
        }
      );
      getMembers();
      setSelectedOptions(undefined);
      setKeyword('');
      toast.success(t('action.add.successfully'));
    } catch (error) {
      toast.error(t('action.add.failed'), error);
    }
    setMemberLoading(false);
  };

  const removeMember = async (member: Member) => {
    if (!detail) {
      return;
    }
    try {
      await request(
        `/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams/${detail.id}/members/${member.clientId}`,
        {
          method: 'DELETE',
        }
      );
      getMembers();
    } catch (error) {
      toast.error(t('action.delete.failed'), error);
    }
  };

  useEffect(() => {
    if (id) {
      getDetail(id);
    }
  }, [getDetail, id]);

  useEffect(() => {
    setName(detail?.name || '');
  }, [detail]);

  useEffect(() => {
    getMembers();
  }, [getMembers, detail]);

  return (
    <>
      <Modal.Header>
        {t(id || detail ? 'account.settings.teams.edit' : 'account.settings.teams.add')}
      </Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="name">{t('label.name')}</label>
            <Input
              disabled={loading}
              id="name"
              placeholder={'name'}
              value={name}
              onChange={(e, data) => setName(data.value)}
              labelPosition="right"
              label={
                <Button
                  disabled={createing || !name}
                  loading={createing || loading}
                  onClick={() => {
                    detail ? updateTeam({ name: name }) : addTeam();
                  }}
                >
                  {t(detail ? 'action.update' : 'action.add')}
                </Button>
              }
            />
          </Form.Field>
          {detail && (
            <>
              <Form.Field>
                <label htmlFor="license">{t('account.settings.teams.avatar')}</label>
                {detail.icon && <img className={styles.teamAvatar} src={detail.icon} alt="team" />}
                <FileInput
                  disabled={loading}
                  id="license"
                  onChange={(data) => {
                    updateTeam({ icon: data });
                  }}
                  accept="image/jpg,image/jpeg,image/png"
                />
              </Form.Field>
              <Form.Field>
                <label>{t('account.settings.teams.member')}</label>
                <div className={styles.members}>
                  {members?.map((item) => (
                    <div className={styles.member} key={item.id}>
                      <Avatar email={item.emailMd5} isEmailMD5 className={styles.memberAvatar} />
                      <span>{item.name}</span>
                      <div className={styles.actions}>
                        <Confirm
                          header={t('account.settings.teams.member.remove')}
                          onConfirm={() => removeMember(item)}
                          trigger={
                            <Button size="mini" color="red">
                              {t('action.delete')}
                            </Button>
                          }
                        />
                      </div>
                    </div>
                  ))}
                  {!showSearch && (
                    <div className={styles.addMember} onClick={() => setShowSearch(true)}>
                      <Icon name="add circle" />
                      <span>{t('account.settings.teams.member.add')}</span>
                    </div>
                  )}
                  {showSearch && (
                    <div className={styles.search}>
                      <Search
                        placeholder={t('label.username')}
                        className={styles.input}
                        loading={searching}
                        onSearchChange={(e, data) => {
                          setKeyword(data.value);
                          search(data.value);
                        }}
                        value={keyword}
                        showNoResults={true}
                        results={options}
                        onBlur={() => {
                          if (!selectedOptions) {
                            return setKeyword('');
                          }
                          if (selectedOptions.name !== keyword) {
                            return setKeyword(selectedOptions.name);
                          }
                        }}
                        onResultSelect={(e, data) => {
                          setKeyword(data.result.name);
                          setSelectedOptions(data.result);
                        }}
                      />
                      <Button
                        disabled={!selectedOptions}
                        loading={memberLoading}
                        onClick={() => {
                          selectedOptions && addMember(selectedOptions);
                        }}
                      >
                        {t('action.add')}
                      </Button>
                    </div>
                  )}
                </div>
              </Form.Field>
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close}>{t('action.close')}</Button>
      </Modal.Actions>
    </>
  );
});

export default function Teams() {
  const [t] = useTranslation();
  const [deleteing, setDeleteing] = useState(false);

  const [teams, { loading, error, reload }] = useAPI<Team[]>(
    `/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams`
  );

  const deleteTeam = async (id: number) => {
    try {
      setDeleteing(true);
      await request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self/teams/${id}`, {
        method: 'DELETE',
      });
      toast.success(t('action.delete.successfully'));
      reload();
    } catch (error) {
      toast.error(t('action.delete.failed'), error);
    }
    setDeleteing(false);
  };

  return (
    <Page title={[t('account.settings.teams'), t('account.settings')]}>
      <PageContainer>
        <EditModal
          modalProps={{
            trigger: <Button>{t('account.settings.teams.add')}</Button>,
            onClose: reload,
          }}
        />
        {error && (
          <APIError message={t('account.settings.teams.error')} error={error} retry={reload} />
        )}
        {loading && (
          <Placeholder className={styles.placeholder}>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}

        <div className={styles.list}>
          {teams?.map((item) => (
            <div key={item.id} className={styles.team}>
              {item.icon ? (
                <img src={item.icon} className={styles.avatar} alt={item.name} />
              ) : (
                <Icon name="users" className={styles.icon} />
              )}

              <span className={styles.name}>{item.name}</span>
              <div className={styles.actions}>
                <EditModal
                  id={item.id}
                  modalProps={{
                    trigger: (
                      <Button size="small" primary>
                        {t('action.edit')}
                      </Button>
                    ),
                    onClose: reload,
                  }}
                />

                <Confirm
                  header={<span>{t('action.delete')}</span>}
                  onConfirm={() => deleteTeam(item.id)}
                  trigger={
                    <Button size="small" color="red" disabled={deleteing}>
                      {t('action.delete')}
                    </Button>
                  }
                />
              </div>
            </div>
          ))}
        </div>
      </PageContainer>
    </Page>
  );
}
