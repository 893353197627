/* eslint-disable i18n/no-chinese-character */
import React, { useCallback, useEffect, useState } from 'react';
import { consoleAPIServer } from 'config';
import { Noop } from 'utils';
import { Button, Form, Input, LinkButton, Modal, withModal } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION, API_VERSION } from 'utils/request';
import { createEventTracker } from 'utils/tracker';

import styles from './index.module.scss';

const track = createEventTracker('Verify');

interface VerificationRequest {
  token: string;
  url: string;
}

const fetchResult = (token: string) =>
  request<{ pass: boolean }>(`/client-center/${CLIENT_CENTER_VERSION}/clientVerificationCallBack`, {
    query: { token },
  });

function VerificationRedirect({
  request: { url, token },
  name,
  onCancel,
  onSucceeded,
}: {
  request: VerificationRequest;
  name: string;
  onCancel: Noop;
  onSucceeded: Noop;
}) {
  useEffect(() => {
    const timer = setInterval(() => {
      fetchResult(token)
        .then(({ pass }) => {
          if (pass) {
            onSucceeded();
          }
          // 忽略未通过的验证
        })
        .catch((error) => toast.error(error.message));
    }, 5000);
    return () => clearInterval(timer);
  }, [token, onSucceeded]);

  const checkResult = () =>
    fetchResult(token)
      .then(({ pass }) => {
        if (pass) {
          onSucceeded();
          return;
        }
        throw new Error('未通过认证');
      })
      .catch((error) => toast.error(error.message));

  return (
    <Modal open>
      <Modal.Header>个人实名认证</Modal.Header>
      <Modal.Content className={styles.centered}>
        <div className={styles.instruction}>
          请使用 <strong>{name}</strong> 本人的支付宝应用扫描二维码完成认证
        </div>
        <img
          src={`${consoleAPIServer}/${API_VERSION}/qrcode?width=280&height=280&url=${encodeURIComponent(
            url
          )}`}
          alt={url}
        />
      </Modal.Content>
      <Modal.Actions>
        <LinkButton content="返回修改资料" onClick={() => onCancel()} />
        <Button content="认证已通过" primary onClick={checkResult} />
      </Modal.Actions>
    </Modal>
  );
}

export default withModal<{ onSucceeded: Noop }>()(({ close, onSucceeded }) => {
  const [name, setName] = useState('');
  const [id, setId] = useState('');

  const [verificationRequest, setVerificationRequest] = useState<VerificationRequest>();
  const handleSuccess = useCallback(() => {
    setVerificationRequest(undefined);
    onSucceeded();
    track('Individual verify succeed');
  }, [onSucceeded]);

  const verify = () => {
    track('Individual verify submit');

    const form = new FormData();
    form.append('clientType', '0');
    form.append('clientName', name);
    form.append('clientIdentity', id);

    return request<VerificationRequest>(
      `/client-center/${CLIENT_CENTER_VERSION}/clients/self/updateClientVerification`,
      {
        method: 'POST',
        body: form,
      }
    )
      .then(setVerificationRequest)
      .catch((error) => toast.error(error.message));
  };

  return (
    <>
      <Modal.Header>个人实名认证</Modal.Header>
      <Modal.Content>
        <Form id="verify" onSubmit={verify}>
          <Form.Field required>
            <label htmlFor="name">姓名</label>
            <Input
              id="name"
              value={name}
              required
              autoFocus
              onChange={(e, data) => {
                setName(data.value);
              }}
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="id">身份证号</label>
            <Input
              id="id"
              value={id}
              required
              onChange={(e, data) => {
                setId(data.value);
              }}
            />
          </Form.Field>
        </Form>
        {verificationRequest && (
          <VerificationRedirect
            request={verificationRequest}
            name={name}
            onCancel={setVerificationRequest}
            onSucceeded={handleSuccess}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <LinkButton content="关闭" onClick={close} />
        <Button type="submit" form="verify" content="下一步" primary />
      </Modal.Actions>
    </>
  );
});
