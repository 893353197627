import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmWithPassword } from 'components/Confirm';
import Page, { PageContainer } from 'components/Page';
import { Button } from 'components/semantic';
import { toast } from 'components/Toast';
import request, { CLIENT_CENTER_VERSION } from 'utils/request';
import { withTracker } from 'utils/tracker';
import { useUser } from 'App/User';

export default withTracker(() => {
  const { t } = useTranslation();
  const [user] = useUser();

  const deleteAccount = useCallback(
    (password: string, close: () => void) =>
      request(`/client-center/${CLIENT_CENTER_VERSION}/clients/self`, {
        method: 'DELETE',
        body: {
          password,
        },
      })
        .then(() => {
          toast.success(
            `${user?.email} ${t('account.unregister.unregistered')}`,
            t('account.unregister.refreshPage')
          );
          close();
        })
        .catch((error) => toast.error(t('account.unregister.fail'), error)),
    [user, t]
  );

  if (!user) {
    return null;
  }
  return (
    <Page title={t('account.unregister')}>
      <PageContainer>
        <p>{t('account.unregister.check')}</p>
        <ul>
          <li>{t('account.unregister.deleteApps')}</li>
          <li>{t('account.unregister.deleteTeams')}</li>
        </ul>
        <ConfirmWithPassword
          trigger={<Button negative content={t('account.unregister')} />}
          header={
            <>
              {t('account.unregister')} <code>{user.email}</code>
            </>
          }
          content={t('account.unregister.irreversible')}
          confirmButtonText={t('account.unregister.confirm')}
          onConfirm={deleteAccount}
        />
      </PageContainer>
    </Page>
  );
});
