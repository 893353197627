import React, {
  useState,
  useImperativeHandle,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { currency } from 'config';
import _ from 'lodash';
import { formatMoney } from 'utils';
import NoData from 'components/NoData';
import { RadioGroup } from 'components/Radio';
import {
  withModal,
  Form,
  Divider,
  LinkButton,
  Button,
  Label,
  Input,
  Checkbox,
} from 'components/semantic';
import { Modal } from 'components/semantic';
import { Item } from 'components/Summary';
import { toast } from 'components/Toast';
import request, { BILLING_CENTER_PREFIX } from 'utils/request';
import { useAPI } from 'utils/use-api';
import useSelected from 'utils/use-selected';
import styles from './index.module.scss';
import { INVOICE_TYPE, Invoice, Charge } from './type';
import classNames from 'classnames';

type RecieverInfo = Pick<
  Invoice,
  'reciever_name' | 'reciever_phone' | 'reciever_address' | 'remark'
>;
const RecieverInfoSegment = React.forwardRef<RecieverInfo, { data?: Partial<RecieverInfo> }>(
  ({ data }, ref) => {
    const { t } = useTranslation();
    const [name, setName] = useState(data?.reciever_name || '');
    const [phone, setPhone] = useState(data?.reciever_phone || '');
    const [address, setAddress] = useState(data?.reciever_address || '');
    const [remark, setRemark] = useState<string | undefined>(data?.remark);
    useImperativeHandle(ref, () => ({
      reciever_address: address,
      reciever_name: name,
      reciever_phone: phone,
      remark,
    }));
    return (
      <>
        <Form.Input
          id="recieverName"
          name="recieverName"
          required
          label={t('billing.invoice.name')}
          value={name}
          onChange={(e, data) => setName(data.value)}
        />
        <Form.Input
          id="recieverPhone"
          name="recieverPhone"
          required
          label={t('billing.invoice.phone')}
          value={phone}
          onChange={(e, data) => setPhone(data.value)}
        />
        <Form.Input
          id="recieverAddress"
          name="recieverAddress"
          required
          label={t('billing.invoice.address')}
          value={address}
          onChange={(e, data) => setAddress(data.value)}
        />
        <Divider section />
        <Form.Input
          id="recieverRemark"
          name="recieverRemark"
          label={t('label.notes')}
          value={remark || ''}
          onChange={(e, data) => setRemark(data.value)}
        />
      </>
    );
  }
);

type VATInfo = Pick<
  Invoice,
  | 'taxpayer_name'
  | 'taxpayer_phone'
  | 'taxpayer_address'
  | 'taxpayer_bank'
  | 'taxpayer_bank_account'
>;

const VATInfoSegment = React.forwardRef<
  VATInfo,
  {
    data?: Partial<VATInfo>;
  }
>(({ data }, ref) => {
  const { t } = useTranslation();
  const [name, setName] = useState(data?.taxpayer_name || '');
  const [phone, setPhone] = useState(data?.taxpayer_phone || '');
  const [address, setAddress] = useState(data?.taxpayer_address || '');
  const [bankName, setBankName] = useState<string>(data?.taxpayer_bank || '');
  const [bankAccount, setBankAccount] = useState<string>(data?.taxpayer_bank_account || '');
  useImperativeHandle(ref, () => ({
    taxpayer_name: name,
    taxpayer_phone: phone,
    taxpayer_address: address,
    taxpayer_bank: bankName,
    taxpayer_bank_account: bankAccount,
  }));
  return (
    <>
      <Form.Input
        id="VATName"
        name="VATName"
        required
        label={t('billing.invoice.VAT.name')}
        value={name}
        onChange={(e, data) => setName(data.value)}
      />
      <Form.Input
        id="VATPhone"
        name="VATPhone"
        required
        label={t('billing.invoice.VAT.phone')}
        value={phone}
        onChange={(e, data) => setPhone(data.value)}
      />
      <Form.Input
        id="VATAddress"
        name="VATAddress"
        required
        label={t('billing.invoice.VAT.address')}
        value={address}
        onChange={(e, data) => setAddress(data.value)}
      />
      <Form.Input
        id="VATBankName"
        name="VATBankName"
        required
        label={t('billing.invoice.VAT.bankName')}
        value={bankName}
        onChange={(e, data) => setBankName(data.value)}
      />
      <Form.Input
        id="VATBankAccount"
        name="VATBankAccount"
        required
        label={t('billing.invoice.VAT.bankAccount')}
        value={bankAccount}
        onChange={(e, data) => setBankAccount(data.value)}
      />
    </>
  );
});

interface ChargeInfo {
  amount: number;
  recharges: number[];
}
const ChargeInfoSegment = React.forwardRef<
  ChargeInfo,
  {
    onSelected: (v: Charge[]) => void;
  }
>(({ onSelected }, ref) => {
  const { t } = useTranslation();
  const [data, { loading }] = useAPI<{
    results: Charge[];
    total: number;
  }>(`${BILLING_CENTER_PREFIX}/payment/transactions`, {
    query: {
      tx_type: 1,
      item_type: 0,
      invoice_status: 0,
    },
  });
  const chargeList = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.results.filter((chargeItem) => chargeItem.currency === currency);
  }, [data]);
  const [selected, toggleSelected, { getSelectedItems }] = useSelected(chargeList);
  const selectedItem = useMemo(() => getSelectedItems(), [getSelectedItems]);
  const totalAmount = useMemo(() => _.sumBy(selectedItem, 'amount'), [selectedItem]);
  useEffect(() => {
    onSelected(selectedItem);
  }, [selectedItem, onSelected]);

  useImperativeHandle(ref, () => ({
    amount: totalAmount,
    recharges: selectedItem.map((item) => item.id),
  }));

  return (
    <Form.Field required>
      <label>{t('billing.invoice.selectedRecharge')}</label>
      <div className={styles.list}>
        {chargeList.map((chargeItem, index) => {
          return (
            <div className={styles.item} key={chargeItem.id}>
              <Checkbox
                className={styles.checkbox}
                checked={selected[index]}
                name="chargeItem"
                id={`${chargeItem.id}`}
                onChange={() => toggleSelected(index)}
              />
              <label htmlFor={`${chargeItem.id}`} className="fill-space">
                <span className={styles.created}>{chargeItem.created}</span>
                <span className={styles.remark}>{chargeItem.remark}</span>
                {chargeItem.payer_name && <Label content={chargeItem.payer_name} color="blue" />}
                <span className="space" />
                <span>
                  {formatMoney(chargeItem.amount)} {currency}
                </span>
              </label>
            </div>
          );
        })}
        {chargeList.length > 0 && (
          <div className={classNames('fill-space', styles.item)}>
            <span className="space" />
            {t('billing.invoice.total')} {formatMoney(totalAmount)} {currency}
          </div>
        )}
        {!loading && chargeList.length === 0 && <NoData header={t('billing.invoice.noCharge')} />}
      </div>
    </Form.Field>
  );
});

const formatInvoiceTitle = (str: string) => str.replace('(', '（').replace(')', '）');
const FORM_ID = 'applyInvoice';
export default withModal<{
  onSuccess?: () => void;
  initData?: Partial<Invoice>;
}>()(({ close, onSuccess, initData }) => {
  const { t } = useTranslation();
  const recieverInfoRef = useRef<RecieverInfo>(null);
  const chargeInfoRef = useRef<ChargeInfo>(null);
  const VATInfoRef = useRef<VATInfo>(null);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Charge[]>([]);
  const [type, setType] = useState<INVOICE_TYPE>(initData?.invoice_type || INVOICE_TYPE.normal);
  const [invoiceTitle, setInvoiceTitle] = useState(initData?.invoice_title); // init
  const [taxpayerNo, setTaxpayerNo] = useState(initData?.taxpayer_no); // init
  const invoiceItem = useMemo(
    // 固定中文
    // eslint-disable-next-line i18n/no-chinese-character
    () => (type === INVOICE_TYPE.normal ? '技术服务费' : '信息技术服务费'),
    [type]
  );
  const initRecieverData = useMemo(
    () =>
      initData
        ? {
            reciever_address: initData.reciever_address,
            reciever_name: initData.reciever_name,
            reciever_phone: initData.reciever_phone,
            remark: initData.remark,
          }
        : undefined,
    [initData]
  );
  const initVATData = useMemo(
    () =>
      initData
        ? {
            taxpayer_name: initData.taxpayer_name,
            taxpayer_phone: initData.taxpayer_phone,
            taxpayer_address: initData.taxpayer_address,
            taxpayer_bank: initData.taxpayer_bank,
            taxpayer_bank_account: initData.taxpayer_bank_account,
          }
        : undefined,
    [initData]
  );
  const totalAmount = useMemo(() => _.sumBy(selectedItem, 'amount'), [selectedItem]);
  const submit = useCallback(async () => {
    try {
      setLoading(true);
      const params = {
        invoice_item: invoiceItem,
        invoice_title: invoiceTitle,
        invoice_type: type,
        taxpayer_no: taxpayerNo,
        ...recieverInfoRef.current,
        ...chargeInfoRef.current,
        ...VATInfoRef.current,
      };
      await request(`${BILLING_CENTER_PREFIX}/clients/self/invoices`, {
        method: 'POST',
        body: params,
      });
      setLoading(false);
      toast.success(t('billing.invoice.apply.successfully'));
      onSuccess?.();
      close();
    } catch (error) {
      setLoading(false);
      toast.error(t('billing.invoice.apply.failed'), error);
    }
  }, [invoiceItem, invoiceTitle, taxpayerNo, type, close, onSuccess, t]);

  return (
    <>
      <Modal.Header content={t('billing.invoice.applyInvoice')} />
      <Modal.Content scrolling>
        <Form id={FORM_ID} onSubmit={submit}>
          <Form.Field required>
            <label htmlFor="type">{t('billing.invoice.type')}</label>
            <RadioGroup
              name="type"
              required
              value={type}
              radios={[
                {
                  label: t('billing.invoice.type.normal'),
                  value: INVOICE_TYPE.normal,
                },
                {
                  label: t('billing.invoice.type.VAT'),
                  value: INVOICE_TYPE.VAT,
                },
              ]}
              onChange={(e, { value }) => setType(value)}
            />
          </Form.Field>
          <Form.Input
            name="invoiceTitle"
            id="invoiceTitle"
            required
            label={t('billing.invoice.title')}
            value={invoiceTitle || ''}
            onChange={(e, data) => {
              setInvoiceTitle(formatInvoiceTitle(data.value));
            }}
          />
          <Form.Field>
            <label>{t('billing.invoice.item')}</label>
            <Label size="big" content={invoiceItem} />
          </Form.Field>
          <ChargeInfoSegment ref={chargeInfoRef} onSelected={setSelectedItem} />
          <Divider section />
          <Form.Field required>
            <label htmlFor="taxpayerNo">{t('billing.invoice.number')}</label>
            <Input
              name="taxpayerNo"
              id="taxpayerNo"
              value={taxpayerNo || ''}
              onChange={(e, data) => setTaxpayerNo(data.value)}
              required
            />
            {type === INVOICE_TYPE.normal && (
              <p className="help-block">{t('billing.invoice.normalHint')}</p>
            )}
          </Form.Field>
          {type === INVOICE_TYPE.VAT && <VATInfoSegment ref={VATInfoRef} data={initVATData} />}
          <Divider section />
          <RecieverInfoSegment data={initRecieverData} ref={recieverInfoRef} />
        </Form>
      </Modal.Content>
      <Modal.Actions className={styles.actions}>
        <Item
          className={styles.item}
          name={t('billing.invoice.total')}
          value={formatMoney(totalAmount)}
          suffix={currency}
        />
        <div>
          <LinkButton onClick={close} content={t('action.cancel')} />
          <Button
            primary
            type="submit"
            form={FORM_ID}
            loading={loading}
            disabled={loading || selectedItem.length === 0}
            content={t('billing.invoice.apply')}
          />
        </div>
      </Modal.Actions>
    </>
  );
});
